import { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import Title from '../components/Title';
import Loading from '../components/Loading';
import { ROUTES } from '../constants/routes';
import { ICargando } from '../models/interfaces/ICargando';
import { INivelEscolarCatalogo } from '../models/interfaces/catalogos/ICatalogo';
import { IAccesoAsistencia } from '../models/interfaces/accesos/IAccesoAsistencia';
import { getMonitorAsistenciasService } from '../services/monitor.service';
import { getCatalogosService } from '../services/catalogos.service';
import TarjetaMonitor from '../components/Monitor/TarjetaMonitor';
import useError from '../hooks/useError';

import '../styles/Monitoreo.scss';

export default function Monitoreo() {
  const [accesosMonitor, setAccesosMonitor] = useState<IAccesoAsistencia[]>([]);
  const [nivelesEscolares, setNivelesEscolares] = useState<INivelEscolarCatalogo[]>([]);
  const [cargando, setCargando] = useState<ICargando>({
    cargando: false,
    mensaje: '',
  });
  const navigate = useNavigate();
  const { setError, ErrorAlert } = useError();

  const obtenerAccesosMonitor = async () => {
    try {
      setCargando({
        cargando: true,
        mensaje: 'Cargando asistencias...',
      });
      const { niveles } = await getMonitorAsistenciasService();
      setAccesosMonitor(niveles);
      obtenerCatalogos();
      calcularTotalAlumnosIngresados();
    } catch (ex) {
      setError(ex);
    } finally {
      setCargando({
        cargando: false,
        mensaje: '',
      });
    }
  };

  const obtenerCatalogos = async () => {
    try {
      const { nivelesEscolares } = await getCatalogosService();
      setNivelesEscolares(nivelesEscolares);
    } catch (ex) {
      setError(ex);
    }
  };

  //i Buscamos cuantos alumnos han ingresado al plantel
  const calcularTotalAlumnosIngresados = () =>
    accesosMonitor
      .map((acc: IAccesoAsistencia) => acc.cantidadEntrada)
      .reduce((prev, nex) => {
        return prev + nex;
      }, 0);

  useEffect(() => {
    obtenerAccesosMonitor();
  }, []);

  return (
    <Container className="monitoreo d-flex flex-column">
      <ErrorAlert />
      {cargando.cargando && <Loading mensaje={cargando.mensaje} />}
      <Title titulo="Monitoreo" ruta={ROUTES.ACCESO} />
      <Row className="my-4">
        <Col xs={12} sm={12} md={12} lg={12}>
          <h3 className="text-center mt-5">
            Alumnos dentro de las instalaciones&nbsp;&nbsp;
            <span className="monitoreo__subtitulo-cantidad">{calcularTotalAlumnosIngresados()}</span>
          </h3>
        </Col>
      </Row>
      {nivelesEscolares.map((nivel: INivelEscolarCatalogo) => (
        <Row className="my-4" key={nivel.id}>
          <>
            {!!accesosMonitor.find(
              (acc: IAccesoAsistencia) => acc.nivel.toLowerCase() === nivel.nivelEscolar.toLowerCase()
            ).totalAlumnos && (
              <Col className="col-r-2" xs={12} sm={12} md={{ span: 10, offset: 1 }} lg={{ span: 10, offset: 1 }}>
                <Card className="monitoreo__nivel" onClick={() => navigate(`${ROUTES.NIVEL}/${nivel.nivelEscolar}`)}>
                  <Card.Body>
                    <TarjetaMonitor
                      titulo={`Nivel ${nivel.nivelEscolar}`}
                      accesos={accesosMonitor.find(
                        (acc: IAccesoAsistencia) => acc.nivel.toLowerCase() === nivel.nivelEscolar.toLowerCase()
                      )}
                    />
                  </Card.Body>
                </Card>
              </Col>
            )}
          </>
        </Row>
      ))}
    </Container>
  );
}
