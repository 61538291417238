import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

import Title from '../components/Title';
import useError from '../hooks/useError';
import Loading from '../components/Loading';
import { ROUTES } from '../constants/routes';
import { ICargando } from '../models/interfaces/ICargando';
import { addUsuariosService, editUsuariosService, getUsuariosService } from '../services/usuarios.service';
import { IRegistroUsuario } from '../models/interfaces/usuario/IRegistroUsuario';
import { registroUsuarioValidationSchema } from '../utils/validations/registro-usuario.validation';

import '../styles/RegistroUsuario.scss';

export default function RegistroUsuario() {
  const [cargando, setCargando] = useState<ICargando>({
    cargando: false,
    mensaje: '',
  });
  const navigate = useNavigate();
  const { id } = useParams();
  const { ErrorAlert, setError } = useError();

  const initialValues: IRegistroUsuario = {
    fullName: '',
    userName: '',
    roles: [],
  };

  const formik = useFormik({
    initialValues,
    validationSchema: registroUsuarioValidationSchema,
    onSubmit: values => {
      const formatRequestData = {
        ...values,
        roles: values.roles.map(role => ({ name: role, selected: true })),
      };
      registrarUsuario(formatRequestData);
    },
  });

  const registrarUsuario = async (dataRequest: IRegistroUsuario) => {
    try {
      setCargando({ cargando: true, mensaje: !!id ? 'Editando Usuario' : 'Guardando Usuario' });
      if (!!id) {
        await editUsuariosService(dataRequest, id);
      } else {
        await addUsuariosService(dataRequest);
      }
      navigate(ROUTES.ROLES);
    } catch (ex) {
      setError(ex);
    } finally {
      setCargando({
        cargando: false,
        mensaje: '',
      });
    }
  };

  const obtenerDetalleUsuario = async () => {
    try {
      const usuarios = await getUsuariosService();
      const detalleUsuario = usuarios.find(usuario => usuario.id === id);
      formik.setFieldValue('fullName', detalleUsuario?.fullName);
      formik.setFieldValue('userName', detalleUsuario?.userName);
      formik.setFieldValue('roles', [...detalleUsuario?.roles]);
      detalleUsuario?.roles.map(role => {
        document.querySelector(`#${role.toLowerCase()}`)?.setAttribute('checked', 'checked');
      });
    } catch (ex) {
      setError(ex);
    }
  };

  useEffect(() => {
    if (!!id) {
      obtenerDetalleUsuario();
    }

    return () => {
      setCargando({
        cargando: false,
        mensaje: '',
      });
    };
  }, []);

  return (
    <Container className="registro-usuario d-flex flex-column">
      {cargando.cargando && <Loading mensaje={cargando.mensaje} />}
      <ErrorAlert />
      <Title titulo={!!id ? 'Editar Usuario' : 'Registro de Usuario'} ruta={ROUTES.ROLES} />
      <form className="mt-5" onSubmit={formik.handleSubmit}>
        <Row className="my-4">
          <Col className="offset-lg-3 offset-md-3" xs={12} sm={12} md={6} lg={6}>
            <Form.Group>
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                name="fullName"
                isInvalid={!!formik.errors.fullName}
                value={formik.values.fullName}
                onChange={formik.handleChange}
                placeholder="Nombre"
              />
              <Form.Control.Feedback className="d-block" type="invalid">
                {formik.errors.fullName}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="my-4">
          <Col className="offset-lg-3 offset-md-3" xs={12} sm={12} md={6} lg={6}>
            <Form.Group>
              <Form.Label>
                Usuario<span className="registro-usuario__required">*</span>
              </Form.Label>
              <Form.Control
                name="userName"
                isInvalid={!!formik.errors.userName}
                value={formik.values.userName}
                onChange={formik.handleChange}
                placeholder="Usuario"
                disabled={!!id}
              />
              <Form.Control.Feedback className="d-block" type="invalid">
                {formik.errors.userName}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="my-4">
          <Col className="offset-lg-3 offset-md-3" xs={12} sm={12} md={6} lg={6}>
            <Form.Group>
              <Form.Label className="m-0">
                Roles<span className="registro-usuario__required">*</span>
              </Form.Label>
              <Form.Check
                className="my-3"
                type="checkbox"
                id="usuario"
                label="Usuario"
                name="roles"
                isInvalid={!!formik.errors.roles}
                value="Usuario"
                onChange={formik.handleChange}
              />
              <Form.Check
                className="my-3"
                type="checkbox"
                id="display"
                label="Display"
                name="roles"
                isInvalid={!!formik.errors.roles}
                value="Display"
                onChange={formik.handleChange}
              />
              <Form.Control.Feedback className="d-block" type="invalid">
                {formik.errors.roles}
              </Form.Control.Feedback>
            </Form.Group>
            <p className="mt-4">
              Los campos con <span className="registro-usuario__required">*</span> son obligatorios.
            </p>
          </Col>
        </Row>
        <Row className="my-4">
          <Col
            className="offset-lg-3 offset-md-3 my-0 d-flex align-items-center justify-content-center"
            xs={12}
            sm={12}
            md={6}
            lg={6}
          >
            <Button
              className="text-white w-75"
              type="submit"
              variant="secondary"
              disabled={!!Object.keys(formik.errors).length}
            >
              Guardar Usuario
            </Button>
          </Col>
        </Row>
      </form>
    </Container>
  );
}
