import { ErrorMessages } from '../../constants/errorMessages';
import * as Yup from 'yup';

export const registroAlumnoValidationSchema = Yup.object().shape({
  foto: Yup.string(),
  padreTutorId: Yup.number().min(0, ErrorMessages.Required),
  nombres: Yup.string().required(ErrorMessages.Required),
  apellidoPaterno: Yup.string().required(ErrorMessages.Required),
  apellidoMaterno: Yup.string(),
  nivelEscolar: Yup.string().required(ErrorMessages.Required),
  gradoGrupo: Yup.string().required(ErrorMessages.Required),
  tarjetaId: Yup.number().required(ErrorMessages.Required).min(1, ErrorMessages.AtLeastOne('1', 'número')),
  fechaNacimiento: Yup.string().required(ErrorMessages.Required),
  sexo: Yup.number().min(0, ErrorMessages.Required),
  telefono: Yup.string()
    .matches(/^\d+$/, ErrorMessages.InvalidValue('números', 'teléfono'))
    .length(10, ErrorMessages.LengthValue(10)),
  domicilio: Yup.string(),
  correo: Yup.string().email(ErrorMessages.InvalidEmail).required(ErrorMessages.Required),
  tipoDeSangre: Yup.string(),
  discapacidad: Yup.boolean(),
  descripcionDiscapacidad: Yup.string(),
  alergias: Yup.boolean(),
  descripcionAlergias: Yup.string(),
  medicamentosControlados: Yup.boolean(),
  descripcionMedicamentosControlados: Yup.string(),
  enfermedadesCronicas: Yup.boolean(),
  descripcionEnfermedadesCronicas: Yup.string(),
});
