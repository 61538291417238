import http from '../utils/http';
import { IRegistrarTutor } from '../models/interfaces/tutores/IRegistrarTutor';
import { IRegistrarContacto } from '../models/interfaces/tutores/IRegistrarContacto';

export const getPadreTutorService = async () => {
  try {
    const response = await http.get('PadreTutor');
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const getPadreTutorByIdService = async (idPadreTutor: number) => {
  try {
    const response = await http.get(`PadreTutor/${idPadreTutor}`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const addPadreTutorService = async (padreTutor: IRegistrarTutor) => {
  try {
    const response = await http.post('PadreTutor', padreTutor);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const addContactoService = async (contacto: IRegistrarContacto) => {
  try {
    const response = await http.post('Contacto', contacto);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const editContactoService = async (contacto: { contactoData: IRegistrarContacto; idContacto: number }) => {
  try {
    const response = await http.put(`Contacto/${contacto.idContacto}`, contacto.contactoData);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const getContactoByIdService = async (idContacto: number) => {
  try {
    const response = await http.get(`Contacto/${idContacto}`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const deleteContactoService = async (idContacto: number) => {
  try {
    const response = await http.delete(`Contacto/${idContacto}`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const deleteAlumnoService = async (idAlumno: number) => {
  try {
    const response = await http.delete(`Alumno/${idAlumno}`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const getParentescosService = async () => {
  try {
    const response = await http.get('Parentesco');
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};
