import { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ViewModuleIcon from '@mui/icons-material/ViewModule';

import Title from '../components/Title';
import Loading from '../components/Loading';
import placeholderImage from '../assets/images/placeholder.png';
import useError from '../hooks/useError';
import { getMonitorAsistenciasService } from '../services/monitor.service';
import { getCatalogosService } from '../services/catalogos.service';
import { getAlumnosFiltrosService } from '../services/alumnos.service';
import { IAccesoAsistencia } from '../models/interfaces/accesos/IAccesoAsistencia';
import { IGradoGrupoCatalogo } from '../models/interfaces/catalogos/ICatalogo';
import { IAlumnoDetalle } from '../models/interfaces/alumnos/IAlumno';
import { ICargando } from '../models/interfaces/ICargando';
import { ROUTES } from '../constants/routes';

import '../styles/Nivel.scss';

export default function Nivel() {
  const [filtroGradoGrupo, setFiltroGradoGrupo] = useState<string>('');
  const [tipoDisplay, setTipoDisplay] = useState<string>('lista');
  const [listaGradosGrupos, setListaGradosGrupos] = useState<IGradoGrupoCatalogo[]>([]);
  const [estudiantes, setEstudiantes] = useState<IAlumnoDetalle[]>([]);
  const [asistenciAccesos, setAsistenciaAccesos] = useState<IAccesoAsistencia>({
    cantidadEntrada: 0,
    cantidadSalida: 0,
    nivel: '',
    totalAlumnos: 0,
    gradosGrupos: [],
  });
  const [cargando, setCargando] = useState<ICargando>({
    cargando: false,
    mensaje: '',
  });
  const { nivel } = useParams();
  const { setError, ErrorAlert } = useError();

  const obtenerAlumnosByNivel = async () => {
    try {
      setCargando({
        cargando: true,
        mensaje: 'Obteniendo alumnos...',
      });
      const response = await getAlumnosFiltrosService(nivel, filtroGradoGrupo);
      setEstudiantes(response);
    } catch (ex) {
      setError(ex);
    } finally {
      setCargando({
        cargando: false,
        mensaje: '',
      });
    }
  };

  const obtenerGradoGrupo = async () => {
    try {
      const { gradosGrupos } = await getCatalogosService();
      setListaGradosGrupos(gradosGrupos);
    } catch (ex) {
      setError(ex);
    }
  };

  const obtenerIngresos = async () => {
    try {
      const { niveles } = await getMonitorAsistenciasService();
      const asistenciaByNivel = niveles.find((niv: IAccesoAsistencia) => niv.nivel === nivel);
      setAsistenciaAccesos(asistenciaByNivel);
    } catch (ex) {
      setError(ex);
    }
  };

  useEffect(() => {
    obtenerAlumnosByNivel();
  }, [filtroGradoGrupo]);

  useEffect(() => {
    obtenerGradoGrupo();
    obtenerIngresos();
    return () => {
      setFiltroGradoGrupo('');
      setTipoDisplay('lista');
      setListaGradosGrupos([]);
      setEstudiantes([]);
      setAsistenciaAccesos({
        cantidadEntrada: 0,
        cantidadSalida: 0,
        nivel: '',
        totalAlumnos: 0,
        gradosGrupos: [],
      });
      setCargando({
        cargando: false,
        mensaje: '',
      });
    };
  }, []);

  return (
    <Container className="nivel d-flex flex-column">
      <ErrorAlert />
      {cargando.cargando && <Loading mensaje={cargando.mensaje} />}
      <Title titulo={`Alumnos Nivel ${nivel}`} ruta={ROUTES.MONITOREO} />
      <Row className="my-5">
        <Col xs={12} sm={12} md={12} lg={12}>
          <h4 className="my-3">
            Alumnos dentro de las instalaciones{' '}
            <span className="fw-bold">
              NIVEL {nivel.toUpperCase()}&nbsp;{asistenciAccesos.cantidadEntrada}
            </span>
          </h4>
        </Col>
      </Row>
      <Row className="my-4 d-flex align-items-end justify-content-between">
        <Col xs={6} sm={6} md={4} lg={4}>
          <Form.Group>
            <Form.Label>Grado/Grupo</Form.Label>
            <Form.Control
              as="select"
              name="gradoGrupo"
              value={filtroGradoGrupo}
              onChange={({ target }) => setFiltroGradoGrupo(target.value)}
            >
              <option value="">Selecciona grado/grupo</option>
              {listaGradosGrupos.map((grado: IGradoGrupoCatalogo) => (
                <option key={grado.id} value={grado.gradoGrupo}>
                  {grado.gradoGrupo}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col className="d-flex align-items-center justify-content-end" xs={6} sm={6} md={8} lg={8}>
          <FormatListBulletedIcon
            className={`nivel__icono${tipoDisplay === 'cuadricula' ? '-disabled' : ''}`}
            onClick={() => setTipoDisplay('lista')}
          />
          <ViewModuleIcon
            className={`nivel__icono${tipoDisplay === 'lista' ? '-disabled' : ''}`}
            onClick={() => setTipoDisplay('cuadricula')}
          />
        </Col>
      </Row>
      <Row className="my-4">
        <Col xs={12} sm={12} md={12} lg={12}>
          {tipoDisplay === 'lista' ? (
            <Table striped hover>
              <thead>
                <tr>
                  <th>Foto</th>
                  <th>Grado/Grupo</th>
                  <th>Nombre Completo</th>
                </tr>
              </thead>
              <tbody>
                {estudiantes.length > 0 ? (
                  <>
                    {estudiantes.map((estudiante: IAlumnoDetalle) => (
                      <tr key={estudiante.id}>
                        <td>
                          <img
                            className={`nivel__foto-lista${estudiante.tipoAcceso !== 1 ? '-disabled' : ''}`}
                            src={estudiante.foto ? `data:image/png;base64,${estudiante.foto}` : placeholderImage}
                            alt="avatar"
                          />
                        </td>
                        <td>{estudiante.gradoGrupo}</td>
                        <td>
                          {estudiante.nombreCompleto ??
                            `${estudiante.nombres} ${estudiante.apellidoPaterno} ${estudiante.apellidoMaterno}`}
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan={5}>No hay estudiantes dentro del plantel.</td>
                  </tr>
                )}
              </tbody>
            </Table>
          ) : (
            <>
              {estudiantes.length > 0 ? (
                <>
                  {estudiantes.map((estudiante: IAlumnoDetalle) => (
                    <img
                      key={estudiante.id}
                      className={`nivel__foto${estudiante.tipoAcceso !== 1 ? '-disabled' : ''}`}
                      src={estudiante.foto ? `data:image/png;base64,${estudiante.foto}` : placeholderImage}
                      alt="avatar"
                    />
                  ))}
                </>
              ) : (
                <h5>No hay estudiantes dentro del plantel.</h5>
              )}
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}
