import http from '../utils/http';

export const buscarColoniasService = async (codigoPostal: string) => {
  try {
    const { data } = await http.get(
      `https://api-localidades.msw.com.mx/api/Colonia/GetColoniaByCodigoPostal/${codigoPostal}`,
      {
        headers: {
          'Content-Type': 'application/json',
          ApiKey: 'ToKen265_S845f1962',
        },
      }
    );
    return data;
  } catch (ex) {
    throw ex;
  }
};
