import { useState, useEffect } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';

import { AlertaEliminarProps } from '../../models/types/modals/alerta-eliminar.type';

export default function AlertaEliminarModal(props: AlertaEliminarProps) {
  const { estado, cambiarEstado, recuperarElemento, dataEliminar } = props;
  const [cargando, setCargando] = useState<boolean>(false);

  const eliminandoUsuario = () => {
    recuperarElemento(dataEliminar.id, dataEliminar.elemento);
    setCargando(true);
  };

  useEffect(() => {
    if (!!!estado) {
      setCargando(false);
    }
  }, [estado]);

  return (
    <Modal show={estado} onHide={() => cambiarEstado(estado => ({ ...estado, estado: false }))}>
      <Modal.Header closeButton>
        <Modal.Title>Eliminar {dataEliminar.elemento}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6>¿Está seguro que quiere eliminar {dataEliminar.elemento.toLowerCase()}?</h6>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => cambiarEstado(estado => ({ ...estado, estado: false }))}>
          Cancelar
        </Button>
        <Button className="text-white" variant="danger" onClick={() => eliminandoUsuario()} disabled={cargando}>
          {cargando ? (
            <>
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              &nbsp;Eliminando
            </>
          ) : (
            <>Eliminar</>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
