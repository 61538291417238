import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Card, Table } from 'react-bootstrap';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { ROUTES } from '../constants/routes';
import { ITutorAlumno, ITutorContacto } from '../models/interfaces/tutores/ITutor';
import { getPadreTutorByIdService, deleteContactoService, deleteAlumnoService } from '../services/tutores.service';
import AlertaEliminarModal from '../components/Modals/AlertaEliminarModal';
import Title from '../components/Title';
import useError from '../hooks/useError';

import '../styles/TutoresPupilo.scss';

export default function TutoresPupilos() {
  const [pupilos, setPupilos] = useState<ITutorAlumno[]>([]);
  const [contactos, setContactos] = useState<ITutorContacto[]>([]);
  const [eliminarModal, setEliminarModal] = useState<{
    estado: boolean;
    data: { id: number; elemento: string };
  }>({
    estado: false,
    data: {
      id: 0,
      elemento: '',
    },
  });

  const { id } = useParams();
  const navigate = useNavigate();
  const { setError, ErrorAlert } = useError();

  const obtenerPupilos = async () => {
    try {
      const padreTutor = await getPadreTutorByIdService(+id);
      setPupilos(padreTutor.alumnos);
      setContactos(padreTutor.contactos);
    } catch (ex) {
      setError(ex);
    }
  };

  const recuperarDataEliminar = async (idElemento: number, origen: string) => {
    try {
      if (origen.toLowerCase().includes('contacto')) {
        await deleteContactoService(idElemento);
        setContactos((contac: ITutorContacto[]) => [
          ...contac.filter((cont: ITutorContacto) => cont.id !== idElemento),
        ]);
      } else if (origen.toLowerCase().includes('alumno')) {
        await deleteAlumnoService(idElemento);
        setPupilos((pup: ITutorAlumno[]) => [...pup.filter((pupi: ITutorAlumno) => pupi.id !== idElemento)]);
      }
    } catch (ex) {
      setError(ex);
    } finally {
      setEliminarModal(estado => ({ ...estado, estado: false }));
    }
  };

  useEffect(() => {
    obtenerPupilos();
    return () => {
      setPupilos([]);
      setContactos([]);
      setEliminarModal({
        estado: false,
        data: {
          id: 0,
          elemento: '',
        },
      });
    };
  }, []);

  return (
    <Container className="tutores-pupilos d-flex flex-column">
      <ErrorAlert />
      <Title titulo="Tutores Pupilos" ruta={ROUTES.TUTORES} />
      <Row className="my-4">
        <Col className="d-flex aling-items-center justify-content-end" xs={12} sm={12} md={12} lg={12}>
          <Button
            variant="secondary"
            className="text-white"
            onClick={() => navigate(`${ROUTES.REGISTRAR_CONTACTO}/${id}`)}
          >
            Agregar Contacto
          </Button>
        </Col>
      </Row>
      <Row className="my-4">
        <Col xs={12} sm={12} md={12} lg={12}>
          <Card>
            <Card.Header>Contactos</Card.Header>
            <Card.Body>
              <Table striped hover>
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Parentesco</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {contactos.length > 0 ? (
                    <>
                      {contactos.map((contacto: ITutorContacto) => (
                        <tr key={contacto.id}>
                          <td>{contacto.nombreCompleto}</td>
                          <td>{contacto.parentesco}</td>
                          <td>
                            <EditIcon
                              className="tutores-pupilos__accion"
                              onClick={() => navigate(`${ROUTES.REGISTRAR_CONTACTO}/${id}/${contacto.id}`)}
                            />
                            &nbsp;
                            <DeleteIcon
                              className="tutores-pupilos__accion"
                              onClick={() =>
                                setEliminarModal({
                                  estado: true,
                                  data: {
                                    id: contacto.id,
                                    elemento: `Contacto ${contacto.nombreCompleto}`,
                                  },
                                })
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={6}>No hay contactos registrados</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="my-4">
        <Col className="d-flex aling-items-center justify-content-end" xs={12} sm={12} md={12} lg={12}>
          <Button
            variant="secondary"
            className="text-white"
            onClick={() => navigate(`${ROUTES.REGISTRAR_ESTUDIANTE}/${id}`)}
          >
            Agregar Alumnos
          </Button>
        </Col>
      </Row>
      <Row className="my-4">
        <Col xs={12} sm={12} md={12} lg={12}>
          <Card>
            <Card.Header>Mis Hijos</Card.Header>
            <Card.Body>
              <Table striped hover>
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {pupilos.length > 0 ? (
                    <>
                      {pupilos.map(pupilo => (
                        <tr key={pupilo.id}>
                          <td>{pupilo.nombreCompleto}</td>
                          <td>
                            <EditIcon
                              className="tutores-pupilos__accion"
                              onClick={() => navigate(`${ROUTES.REGISTRAR_ESTUDIANTE}/${id}/${pupilo.id}`)}
                            />
                            &nbsp;
                            <DeleteIcon
                              className="tutores-pupilos__accion"
                              onClick={() =>
                                setEliminarModal({
                                  estado: true,
                                  data: {
                                    id: pupilo.id,
                                    elemento: `Alumno ${pupilo.nombreCompleto}`,
                                  },
                                })
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={6}>No hay alumnos registrados</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <AlertaEliminarModal
        estado={eliminarModal.estado}
        cambiarEstado={setEliminarModal}
        recuperarElemento={recuperarDataEliminar}
        dataEliminar={eliminarModal.data}
      />
    </Container>
  );
}
