export const ROUTES = {
  ROOT: '/',
  ACCESO: '/acceso',
  ESTUDIANTES: '/estudiantes',
  MONITOREO: '/monitoreo',
  REPORTES: '/reportes',
  AREAS_ACCESO: '/areas-acceso',
  ROLES: '/roles',
  TUTORES: '/tutores',
  TUTORES_PUPILOS: '/tutores/pupilos',
  REGISTRAR_TUTOR: '/registrar-tutor',
  REGISTRAR_CONTACTO: '/registrar-contacto',
  REGISTRAR_USUARIO: '/registrar-usuario',
  REGISTRAR_ESTUDIANTE: '/registrar-estudiante',
  EDITAR_USUARIO: '/editar-usuario',
  CATALOGOS: '/catalogos',
  NIVEL: '/nivel',
  TEMAS_PLATAFORMA: '/temas-plataforma',
  TARJETAS: '/tarjetas',
  DEBUG: '/debug',
};
