import http from '../utils/http';
import { IRegistrarAlumno } from '../models/interfaces/alumnos/IRegistroAlumno';

export const getAlumnosService = async () => {
  try {
    const response = await http.get('Alumno');
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const getAlumnoService = async (idTutor: number) => {
  try {
    const response = await http.get(`Alumno/${idTutor}`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const getAlumnosBusquedaService = async (queryParams: string = '') => {
  try {
    const response = await http.get('Alumno/GetListByFilterAlumnos', {
      params: {
        filtro: queryParams,
      },
    });
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const getAlumnosFiltrosService = async (queryNivel: string = '', queryGradoGrupo: string = '') => {
  try {
    const response = await http.get('Alumno/GetListByNivelGradoGrupoAlumnos', {
      params: {
        nivel: queryNivel,
        gradoGrupo: queryGradoGrupo,
      },
    });
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const addAlumnoService = async (padreTutor: IRegistrarAlumno) => {
  try {
    const response = await http.post('Alumno', padreTutor);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const editAlumnoService = async (padreTutor: IRegistrarAlumno, idAlumno: number) => {
  try {
    const response = await http.put(`Alumno/${idAlumno}`, padreTutor);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};
