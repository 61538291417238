export enum CATALOGOS {
  SELECCION,
  NIVEL,
  GRADO_GRUPO,
}

export enum CATALOGOS_TEXTO {
  SELECCION_TEXTO = 'Selecciona un catálogo',
  NIVEL_TEXTO = 'Niveles',
  GRADO_GRUPO_TEXTO = 'Grado/Grupo',
}
