import { useState, useEffect, useRef } from 'react';
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';

import { AgregarTarjetaProps } from '../../models/types/modals/agregar-tarjeta.type';

export default function AgregarTarjeta(props: AgregarTarjetaProps) {
  const { estado, cambiarEstado, recuperarDato } = props;
  const [codigoTarjeta, setCodigoTarjeta] = useState<string>('');
  const refCampo = useRef(null);

  useEffect(() => {
    if (estado) {
      refCampo.current.focus();
    }
  }, [estado]);

  return (
    <Modal show={estado} onHide={() => cambiarEstado(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Agregar Tarjeta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6 className="fw-normal">
          Para capturar el número de la tarjeta, el usuario debe de pasar su tarjeta por el dispositivo de salida.
        </h6>
        <Row className="my-3">
          <Col xs={12} sm={12} md={12} lg={12}>
            <Form.Group>
              <Form.Label>ID Tarjeta</Form.Label>
              <Form.Control
                ref={refCampo}
                name="codigoTarjeta"
                value={codigoTarjeta}
                onChange={({ target }) => setCodigoTarjeta(target.value)}
                placeholder="0000"
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={() => cambiarEstado(false)}>
          Cancelar
        </Button>
        <Button className="text-white" variant="secondary" onClick={() => recuperarDato(codigoTarjeta)}>
          Aceptar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
