import { useState, useEffect, useRef } from 'react';
import { Modal, Form, Button, Row, Col, Spinner } from 'react-bootstrap';

import { AgregarCatalogoProps } from '../../models/types/modals/agregar-catalogo.type';

export default function AgregarCatalogoModal(props: AgregarCatalogoProps) {
  const { estado, cambiarEstado, recuperarDato, dataCatalogo, guardando } = props;
  const [descripcionCatalogo, setDescripcionCatalogo] = useState<string>('');
  const refCampo = useRef(null);

  const { catalogo } = dataCatalogo;
  const { estadoGuardando, cambiarEstadoGuardando } = guardando;

  useEffect(() => {
    if (estado) {
      refCampo.current.focus();
    } else {
      setDescripcionCatalogo('');
    }
  }, [estado]);

  return (
    <Modal show={estado} onHide={() => cambiarEstado(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Agregar {catalogo}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="my-3">
          <Col xs={12} sm={12} md={12} lg={12}>
            <Form.Group>
              <Form.Label>Descripción {catalogo}</Form.Label>
              <Form.Control
                ref={refCampo}
                name="descripcion"
                value={descripcionCatalogo}
                onChange={({ target }) => setDescripcionCatalogo(target.value)}
                placeholder={`Descripción ${catalogo}`}
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={() => cambiarEstado(false)}>
          Cancelar
        </Button>
        <Button
          className="text-white"
          variant="secondary"
          onClick={() => {
            cambiarEstadoGuardando(true);
            recuperarDato(descripcionCatalogo);
          }}
          disabled={estadoGuardando}
        >
          {estadoGuardando ? (
            <>
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              &nbsp; Guardando
            </>
          ) : (
            'Guardar'
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
