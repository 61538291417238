import { ErrorMessages } from '../../constants/errorMessages';
import * as Yup from 'yup';

export const registroTutorValidationSchema = Yup.object().shape({
  nombreCompleto: Yup.string().required(ErrorMessages.Required),
  parentescoId: Yup.number().min(1, ErrorMessages.Required),
  telefono: Yup.string()
    .required(ErrorMessages.Required)
    .matches(/^\d+$/, ErrorMessages.InvalidValue('números'))
    .length(10, ErrorMessages.LengthValue(10)),
  autorizoSerContactado: Yup.boolean(),
  codigoPostal: Yup.string()
    .required(ErrorMessages.Required)
    .matches(/^\d+$/, ErrorMessages.InvalidValue('números'))
    .length(5, ErrorMessages.LengthValue(5)),
  estado: Yup.string().required(ErrorMessages.Required),
  municipio: Yup.string().required(ErrorMessages.Required),
  colonia: Yup.string().required(ErrorMessages.Required),
  calle: Yup.string().required(ErrorMessages.Required),
  numeroExterior: Yup.string().required(ErrorMessages.Required),
  esContactoPrioritario: Yup.boolean(),
});
