import { IAcceso } from '../../models/interfaces/IAcceso';

export const accesosMock: IAcceso[] = [
  {
    id: 1,
    acceso: 'Entrada Principal',
  },
  {
    id: 2,
    acceso: 'Salida Principal',
  },
  {
    id: 3,
    acceso: 'Entrada Secundaria',
  },
];
