export enum TIPO_ACCESO {
  DEFAULT,
  ENTRADA,
  SALIDA,
}

export enum TIPO_ACCESO_TEXTO {
  DEFAULT_TEXTO = '',
  ENTRADA_TEXTO = 'ENTRADA',
  SALIDA_TEXTO = 'SALIDA',
}
