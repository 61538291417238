import { ISesionContext } from '@app/models/interfaces/ISesionContext';
import { createContext } from 'react';

const SesionContext = createContext<ISesionContext>({
  dataUser: {
    usuario: null,
    roles: [],
  },
  setDataUser: () => {},
});

export default SesionContext;
