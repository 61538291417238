import { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Card, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';

import Title from '../components/Title';
import Loading from '../components/Loading';
import { IRegistrarContacto } from '../models/interfaces/tutores/IRegistrarContacto';
import { ICargando } from '../models/interfaces/ICargando';
import { IColonia } from '../models/interfaces/IColonia';
import { registroContactoValidationSchema } from '../utils/validations/registro-contacto.validation';
import { buscarColoniasService } from '../services/localidades.service';
import { addContactoService, editContactoService, getContactoByIdService } from '../services/tutores.service';
import { getParentescosService } from '../services/tutores.service';
import { IParentesco } from '../models/interfaces/tutores/IParentesco';
import { ROUTES } from '../constants/routes';
import useError from '../hooks/useError';

import '../styles/RegistroContacto.scss';

export default function RegistroContacto() {
  const [cargando, setCargando] = useState<ICargando>({
    cargando: false,
    mensaje: '',
  });
  const [mensajeColonias, setMensajeColonias] = useState<string>('');
  const [colonias, setColonias] = useState<IColonia[]>([]);
  const [parentescos, setParentescos] = useState<IParentesco[]>([]);

  const { id, idContacto } = useParams();
  const initialValues: IRegistrarContacto = {
    nombreCompleto: '',
    parentescoId: 0,
    padreTutorId: 0,
    telefono: '',
    codigoPostal: '',
    estado: '',
    municipio: '',
    colonia: '',
    calle: '',
    numeroExterior: '',
  };
  const { setError, ErrorAlert } = useError();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues,
    onSubmit: (values: IRegistrarContacto) => guardarContacto({ ...values, padreTutorId: +id }),
    validationSchema: registroContactoValidationSchema,
  });

  const obtenerLocalidad = async (codigoPostal: string) => {
    try {
      if (codigoPostal.length > 4 || !!!Object.keys(formik.errors).includes('codigoPostal')) {
        setCargando({
          cargando: true,
          mensaje: 'Buscando colonias',
        });
        const response = await buscarColoniasService(codigoPostal);
        setColonias(response);
        if (!!response.length) {
          formik.setFieldValue('estado', response[0].estadoNombre);
          formik.setFieldValue('municipio', response[0].municipioNombre);
        } else {
          setMensajeColonias('No se encontraron colonias para el código postal');
        }
      } else if (codigoPostal.length < 5) {
        setColonias([]);
        formik.setFieldValue('estado', '');
        formik.setFieldValue('municipio', '');
        formik.setFieldValue('colonia', '');
        setMensajeColonias('');
      }
    } catch (ex) {
      setError(ex);
    } finally {
      setCargando({
        cargando: false,
        mensaje: '',
      });
    }
  };

  const obtenerParentescos = async () => {
    try {
      const listaParentescos = await getParentescosService();
      setParentescos(listaParentescos);
    } catch (ex) {
      setError(ex);
    }
  };

  const obtenerDetalleContacto = async () => {
    try {
      setCargando({
        cargando: true,
        mensaje: 'Obteniendo detalle del contacto',
      });
      const response = await getContactoByIdService(+idContacto);
      if (response) {
        obtenerLocalidad(response.codigoPostal);
        formik.setFieldValue('parentescoId', response.parentescoId);
        formik.setFieldValue('nombreCompleto', response.nombreCompleto);
        formik.setFieldValue('telefono', response.telefono);
        formik.setFieldValue('codigoPostal', response.codigoPostal);
        formik.setFieldValue('colonia', response.colonia);
        formik.setFieldValue('calle', response.calle);
        formik.setFieldValue('numeroExterior', response.numeroExterior);
      }
    } catch (ex) {
      setError(ex);
    } finally {
      setCargando({
        cargando: false,
        mensaje: '',
      });
    }
  };

  const guardarContacto = async (requestContactoData: IRegistrarContacto) => {
    try {
      setCargando({
        cargando: true,
        mensaje: idContacto ? 'Editando Contacto' : 'Guardando Contacto',
      });
      if (idContacto) {
        await editContactoService({
          contactoData: { ...requestContactoData, parentescoId: +requestContactoData.parentescoId },
          idContacto: +idContacto,
        });
      } else {
        await addContactoService({
          ...requestContactoData,
          parentescoId: +requestContactoData.parentescoId,
        });
      }
      navigate(`${encodeURI(`${ROUTES.TUTORES_PUPILOS}/${id}`)}`);
    } catch (ex) {
      setError(ex);
    } finally {
      setCargando({
        cargando: false,
        mensaje: '',
      });
    }
  };

  useEffect(() => {
    obtenerParentescos();
    if (idContacto) {
      obtenerDetalleContacto();
    }
    return () => {
      setColonias([]);
      setMensajeColonias('');
      setCargando({
        cargando: false,
        mensaje: '',
      });
      formik.resetForm();
    };
  }, []);

  return (
    <Container className="registro-contacto d-flex flex-column">
      {cargando.cargando && <Loading mensaje={cargando.mensaje} />}
      <ErrorAlert />
      <Title titulo="Registro Contacto" ruta={`${encodeURI(`${ROUTES.TUTORES_PUPILOS}/${id}`)}`} />
      <form onSubmit={formik.handleSubmit}>
        <Row className="mt-4">
          <Col xs={12} sm={12} md={6} lg={6}>
            <p>
              Los campos con <span className="registro-contacto__required">*</span> son obligatorios.
            </p>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={12} sm={12} md={5} lg={6}>
            <Form.Group>
              <Form.Label>
                Nombre Completo Contacto&nbsp;<span className="registro-contacto__required">*</span>
              </Form.Label>
              <Form.Control
                isInvalid={!!formik.errors.nombreCompleto}
                name="nombreCompleto"
                value={formik.values.nombreCompleto}
                onChange={formik.handleChange}
                placeholder="Nombre Completo Contacto"
              />
              <Form.Control.Feedback className="d-block" type="invalid">
                {formik.errors.nombreCompleto}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} sm={12} md={4} lg={3}>
            <Form.Group>
              <Form.Label>
                Parentesco&nbsp;<span className="registro-contacto__required">*</span>
              </Form.Label>
              <Form.Control
                as="select"
                isInvalid={!!formik.errors.parentescoId}
                name="parentescoId"
                value={formik.values.parentescoId}
                onChange={formik.handleChange}
                placeholder="Parantesco"
              >
                <option value={0}>Seleccionar Parentesco</option>
                {parentescos.map((parent: IParentesco) => (
                  <option key={parent.id} value={parent.id}>
                    {parent.parentesco}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback className="d-block" type="invalid">
                {formik.errors.parentescoId}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={6} sm={6} md={3} lg={3}>
            <Form.Group>
              <Form.Label>
                Teléfono&nbsp;<span className="registro-contacto__required">*</span>
              </Form.Label>
              <Form.Control
                isInvalid={!!formik.errors.telefono}
                name="telefono"
                value={formik.values.telefono}
                onChange={formik.handleChange}
                placeholder="Teléfono"
              />
              <Form.Control.Feedback className="d-block" type="invalid">
                {formik.errors.telefono}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="my-4">
          <Col xs={12} sm={12} md={12} lg={12}>
            <Card>
              <Card.Header>
                Domicilio Actual{' '}
                {!!mensajeColonias && !!!Object.keys(formik.errors).includes('codigoPostal') && (
                  <>
                    -&nbsp;<span className="text-danger fw-500">{mensajeColonias}</span>
                  </>
                )}
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col xs={4} sm={4} md={3} lg={3}>
                    <Form.Group>
                      <Form.Label>
                        Código Postal&nbsp;<span className="registro-contacto__required">*</span>
                      </Form.Label>
                      <Form.Control
                        isInvalid={!!formik.errors.codigoPostal}
                        name="codigoPostal"
                        value={formik.values.codigoPostal}
                        onChange={event => {
                          formik.handleChange(event);
                          obtenerLocalidad(event.target.value);
                        }}
                        placeholder="Código Postal"
                      />
                      <Form.Control.Feedback className="d-block" type="invalid">
                        {formik.errors.codigoPostal}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4}>
                    <Form.Group>
                      <Form.Label>Estado</Form.Label>
                      <Form.Control
                        isInvalid={!!formik.errors.estado}
                        name="estado"
                        value={formik.values.estado}
                        onChange={formik.handleChange}
                        placeholder="Estado"
                        disabled
                      />
                      <Form.Control.Feedback className="d-block" type="invalid">
                        {formik.errors.estado}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={4} sm={4} md={5} lg={5}>
                    <Form.Group>
                      <Form.Label>Municipio</Form.Label>
                      <Form.Control
                        isInvalid={!!formik.errors.municipio}
                        name="municipio"
                        value={formik.values.municipio}
                        onChange={formik.handleChange}
                        placeholder="Municipio"
                        disabled
                      />
                      <Form.Control.Feedback className="d-block" type="invalid">
                        {formik.errors.municipio}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="my-4">
                  <Col xs={6} sm={6} md={6} lg={6}>
                    <Form.Group>
                      <Form.Label>
                        Colonia&nbsp;<span className="registro-contacto__required">*</span>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        isInvalid={!!formik.errors.colonia}
                        name="colonia"
                        value={formik.values.colonia}
                        onChange={formik.handleChange}
                        placeholder="Colonia"
                      >
                        <option value="">Seleccionar Colonia</option>
                        {colonias.map((col: IColonia) => (
                          <option key={col.id} value={col.nombreColonia}>
                            {col.nombreColonia.toUpperCase()}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback className="d-block" type="invalid">
                        {formik.errors.colonia}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4}>
                    <Form.Group>
                      <Form.Label>
                        Calle&nbsp;<span className="registro-contacto__required">*</span>
                      </Form.Label>
                      <Form.Control
                        isInvalid={!!formik.errors.calle}
                        name="calle"
                        value={formik.values.calle}
                        onChange={formik.handleChange}
                        placeholder="Calle"
                      />
                      <Form.Control.Feedback className="d-block" type="invalid">
                        {formik.errors.calle}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={2} sm={2} md={2} lg={2}>
                    <Form.Group>
                      <Form.Label>
                        Número Exterior&nbsp;<span className="registro-contacto__required">*</span>
                      </Form.Label>
                      <Form.Control
                        isInvalid={!!formik.errors.numeroExterior}
                        name="numeroExterior"
                        value={formik.values.numeroExterior}
                        onChange={formik.handleChange}
                        placeholder="Número Exterior"
                      />
                      <Form.Control.Feedback className="d-block" type="invalid">
                        {formik.errors.numeroExterior}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col className="d-flex align-items-end justify-content-end" xs={12} sm={12} md={12} lg={12}>
                    <Button className="text-white" type="submit" variant="secondary">
                      {idContacto ? 'Editar Contacto' : 'Guardar Contacto'}
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </form>
    </Container>
  );
}
