import http from '../utils/http';
import { IAgregarNivelCatalogo, IAgregarGradoGrupoCatalogo } from '../models/interfaces/catalogos/IAgregarCatalogo';

export const getCatalogosService = async () => {
  try {
    const response = await http.get('GadoGrupoNivel/GetGradosGruposNivelesEscolares');
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const addCatalogosService = async (
  catalogo: string,
  dataCatalogo: IAgregarNivelCatalogo | IAgregarGradoGrupoCatalogo
) => {
  try {
    const response = await http.post(
      `GadoGrupoNivel/${catalogo.toLowerCase().includes('nivel') ? 'PostNivelEscolar' : 'PostGradoGrupo'}`,
      dataCatalogo
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const deleteCatalogosService = async (idCatalogo: number, catalogo: string) => {
  try {
    const response = await http.delete(
      `GadoGrupoNivel/${
        catalogo.toLowerCase().includes('nivel') ? 'DeleteNivelEscolar' : 'DeleteGradoGrupo'
      }/${idCatalogo}`
    );
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};
