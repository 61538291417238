import { tarjetaProps } from '../../models/types/monitor/tarjeta.type';
import GradosMonitor from '../../components/Monitor/Grados';

export default function TarjetaMonitor(props: tarjetaProps) {
  const { titulo, accesos } = props;

  return (
    <>
      <div className="d-flex align-items-center justify-content-between m-4">
        <h4>{titulo}</h4>
        <h4>
          Ingreso de Alumnos: <span className="fw-bold">{accesos.cantidadEntrada}</span>
        </h4>
      </div>
      <div className="my-5 w-100 d-flex align-items-center justify-content-around">
        <GradosMonitor gradosNivel={accesos.gradosGrupos} />
      </div>
    </>
  );
}
