import { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { ROUTES } from '../constants/routes';
import { ITarjeta } from '../models/interfaces/alumnos/ITarjeta';
import Title from '../components/Title';
import AgregarTarjeta from '../components/Modals/AgregarTarjeta';

export default function Tarjetas() {
  const [tarjetas, setTarjetas] = useState<ITarjeta[]>([]);
  const [estadoModal, setEstadoModal] = useState<boolean>(false);
  const { id } = useParams();

  const obtenerTarjetas = () => {
    console.log(`Estudainte ${id}`);
    setTarjetas([]);
  };

  const obtenerCodigoTarjeta = (codigo: string) => {
    console.log(codigo);
  };

  useEffect(() => {
    obtenerTarjetas();
  }, []);

  return (
    <Container className="d-flex flex-column">
      <Title titulo="Lista Tarjetas" ruta={ROUTES.ESTUDIANTES} />
      <Row className="my-4">
        <Col className="d-flex align-items-center justify-content-end" xs={12} sm={12} md={12} lg={12}>
          <Button variant="secondary" className="text-white" onClick={() => setEstadoModal(true)}>
            Agregar Tarjeta
          </Button>
        </Col>
      </Row>
      <Row className="my-3">
        <Col xs={12} sm={12} md={12} lg={12}>
          <Table striped hover>
            <thead>
              <tr>
                <th>ID Tarjeta</th>
                <th>Fecha Registro</th>
                <th>Estado</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {tarjetas.length > 0 ? (
                <>
                  {tarjetas.map((tarjeta: ITarjeta) => (
                    <tr key={tarjeta.id}>
                      <td>{tarjeta.codigo}</td>
                      <td>{tarjeta.fechaRegistro}</td>
                      <td>{tarjeta.estado}</td>
                      <td>Acciones</td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={4}>No hay tarjetas registradas</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      <AgregarTarjeta estado={estadoModal} cambiarEstado={setEstadoModal} recuperarDato={obtenerCodigoTarjeta} />
    </Container>
  );
}
