import http from '../utils/http';
import { IAuth } from '@app/models/interfaces/IAuth';
import { IUsuarioSesionContext } from '@app/models/interfaces/ISesionContext';

export const authService = async (dataRequest: IAuth, addDataUser: Function) => {
  try {
    const { data } = await http.post('Auth/login', dataRequest);
    localStorage.setItem('credenciales', JSON.stringify(data));
    addDataUser((userData: IUsuarioSesionContext) => ({
      ...userData,
      usuario: data.usuario,
      roles: data.roles.map(role => role.toLowerCase()),
    }));
  } catch (ex) {
    throw ex;
  }
};

export const logoutService = (removeDataUser: Function) => {
  removeDataUser(() => ({
    usuario: null,
    roles: [],
  }));
  localStorage.removeItem('credenciales');
};
