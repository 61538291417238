import { useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import Home from './pages/Home';
import Acceso from './pages/Acceso';
import Catalogos from './pages/Catalogos';
import Estudiantes from './pages/Estudiantes';
import DetalleEstudiante from './pages/DetalleEstudiante';
import Monitoreo from './pages/Monitoreo';
import Reportes from './pages/Reportes';
import AreaAcceso from './pages/AreaAcceso';
import Tutores from './pages/Tutores';
import TutoresPupilos from './pages/TutoresPupilos';
import RegistroTutor from './pages/RegistroTutor';
import RegistroContacto from './pages/RegistroContacto';
import RegistroEstudiante from './pages/AgregarEstudiante';
import Roles from './pages/Roles';
import RegistroUsuario from './pages/RegistroUsuario';
import Nivel from './pages/Nivel';
import Tarjetas from './pages/Tarjetas';
import Debugger from './pages/Debug';
import TemasPlataforma from './pages/Temas';
import NavMenu from './components/NavMenu';
import Footer from './components/Footer';
import SesionContext from './context/sesionContext';
import { ROUTES } from './constants/routes';
import { ISesionContext, IUsuarioSesionContext } from './models/interfaces/ISesionContext';

import './styles/App.scss';

const App = () => {
  const [dataUser, setDataUser] = useState<IUsuarioSesionContext>({
    usuario: JSON.parse(localStorage.getItem('credenciales'))?.usuario,
    roles: JSON.parse(localStorage.getItem('credenciales'))?.roles,
  });
  const contextData: ISesionContext = {
    dataUser,
    setDataUser,
  };

  const navegacion = pagina => {
    if (!!dataUser.usuario) {
      return pagina;
    } else {
      return <Navigate to={ROUTES.ROOT} />;
    }
  };

  return (
    <SesionContext.Provider value={contextData}>
      <main className="d-flex flex-column justify-content-between">
        {!!dataUser.usuario && <NavMenu />}
        <div className="flex-grow-1">
          <Routes>
            <Route path={ROUTES.ROOT} element={!!dataUser.usuario ? <Navigate to={ROUTES.ACCESO} /> : <Home />} />
            <Route path={ROUTES.ACCESO} element={navegacion(<Acceso />)} />
            <Route path={ROUTES.ESTUDIANTES} element={navegacion(<Estudiantes />)} />
            <Route path={`${ROUTES.ESTUDIANTES}/:id`} element={navegacion(<DetalleEstudiante />)} />
            <Route path={ROUTES.MONITOREO} element={navegacion(<Monitoreo />)} />
            <Route path={ROUTES.REPORTES} element={navegacion(<Reportes />)} />
            <Route path={ROUTES.AREAS_ACCESO} element={navegacion(<AreaAcceso />)} />
            <Route path={ROUTES.ROLES} element={navegacion(<Roles />)} />
            <Route path={ROUTES.TUTORES} element={navegacion(<Tutores />)} />
            <Route path={`${ROUTES.REGISTRAR_CONTACTO}/:id`} element={navegacion(<RegistroContacto />)} />
            <Route path={`${ROUTES.REGISTRAR_CONTACTO}/:id/:idContacto`} element={navegacion(<RegistroContacto />)} />
            <Route path={`${ROUTES.TUTORES_PUPILOS}/:id`} element={navegacion(<TutoresPupilos />)} />
            <Route path={ROUTES.REGISTRAR_TUTOR} element={navegacion(<RegistroTutor />)} />
            <Route path={`${ROUTES.REGISTRAR_ESTUDIANTE}/:id`} element={navegacion(<RegistroEstudiante />)} />
            <Route path={`${ROUTES.REGISTRAR_ESTUDIANTE}/:id/:idAlumno`} element={navegacion(<RegistroEstudiante />)} />
            <Route path={ROUTES.REGISTRAR_USUARIO} element={navegacion(<RegistroUsuario />)} />
            <Route path={`${ROUTES.EDITAR_USUARIO}/:id`} element={navegacion(<RegistroUsuario />)} />
            <Route path={`${ROUTES.TARJETAS}/:id`} element={navegacion(<Tarjetas />)} />
            <Route path={`${ROUTES.NIVEL}/:nivel`} element={navegacion(<Nivel />)} />
            <Route path={ROUTES.CATALOGOS} element={navegacion(<Catalogos />)} />
            <Route path={ROUTES.DEBUG} element={<Debugger/>}/>
            <Route path={ROUTES.TEMAS_PLATAFORMA} element={navegacion(<TemasPlataforma />)} />
          </Routes>
        </div>
        <Footer />
      </main>
    </SesionContext.Provider>
  );
};

export default App;
