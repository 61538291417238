import http from '../utils/http';

export const getMonitorAsistenciasService = async () => {
  try {
    const response = await http.get('Acceso/GetMonitorAsistencia');
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};
