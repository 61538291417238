import { useState, useEffect } from 'react';
import { Row, Col, Form, Container } from 'react-bootstrap';
import * as signalR from '@microsoft/signalr';

import Title from '../components/Title';
import { IDebug } from '../models/interfaces/IDebug';

export default function Debug() {
  const [conexionSignalR, setConexionSignalR] = useState<any>(null);
  const [formatoResultado, setFormatoResultado] = useState<string[]>([]);
  const [resultado, setResultado] = useState<IDebug>({
    variable: '',
    valor: ''
  });

  //i Escuchar el socket cada que la lectora se use
  const listenSocket = () => {
    if (conexionSignalR) {
      conexionSignalR
        .start()
        .then(() => {
          conexionSignalR.on('DebugLectora', (response: IDebug) => {
            if (response) {
              setResultado(response);
            }
          });
        })
        .catch(error => error);
    }
  };

  //i Conexión a SignalR
  const obtenerEntradaSalidaRealTime = () => {
    const conexion = new signalR.HubConnectionBuilder()
      .withUrl('/IngresoSalidaHub', {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();
    setConexionSignalR(conexion);
  };

  useEffect(() => {
    listenSocket();
    return () => {
      if (conexionSignalR) {
        conexionSignalR.off('Debugger Actualizado');
        conexionSignalR.stop();
        setResultado({
          variable: '',
          valor: ''
        });
      }
    };
  }, [conexionSignalR]);

  useEffect(() => {
    if(!!resultado.variable && !!resultado.valor){
      setFormatoResultado((cadenaResult:string[]) => [...cadenaResult, `Variable: ${resultado.variable}\nValor: ${resultado.valor}\n===============================\n`]);
    }
  }, [resultado]);

  useEffect(() => {
    obtenerEntradaSalidaRealTime();
  }, []);

  return (
    <Container className="d-flex flex-column">
      <Title titulo='Debugger ESP32'/>
      <Row className='mt-5 mb-4'>
        <Col sm={12} md={12} lg={12}>
          <Form.Group>
            <Form.Label>Resultado Debugger</Form.Label>
            <Form.Control as='textarea' rows={15} name='resultado' placeholder='Resultado Debugger' defaultValue={formatoResultado.join('')}/>
          </Form.Group>
        </Col>
      </Row>
    </Container>
  )
}
