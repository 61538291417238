import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import useSesion from '../hooks/useSesion';
import logotipoBlanco from '../assets/images/logotipo-blanco.svg';
import { ROUTES } from '../constants/routes';
import { logoutService } from '../services/auth.service';

import '../styles/components/NavMenu.component.scss';

export default function NavMenu() {
  const { setDataUser, dataUser } = useSesion();

  //i Se pasa el rol, si existe ese role entonces podemos condicionar que items mostrar o cuales no en el navbar
  const esRole = (role: string) => dataUser.roles.map((rol: string) => rol.toLowerCase()).includes(role.toLowerCase());

  return (
    <Navbar className="navmenu px-3" bg="primary" expand="lg" variant="dark" sticky="top" collapseOnSelect>
      <Container fluid>
        <Navbar.Brand as={Link} to={ROUTES.ACCESO}>
          <img src={logotipoBlanco} width={120} height={35} alt="Tara" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <div className="navmenu__nav-items">
            <Nav>
              {esRole('Administrador') && (
                <>
                  <Nav.Link as={Link} to={ROUTES.ACCESO}>
                    Acceso Escolar
                  </Nav.Link>
                  <Nav.Link as={Link} to={ROUTES.CATALOGOS}>
                    Catálogos
                  </Nav.Link>
                  {/* <Nav.Link as={Link} to={ROUTES.ROLES}>
                    Roles Usuario
                  </Nav.Link> */}
                </>
              )}
            </Nav>
            <Nav>
              <Nav.Link onClick={() => logoutService(setDataUser)}>Cerrar Sesión</Nav.Link>
            </Nav>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
