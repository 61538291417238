import { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Table } from 'react-bootstrap';

import Title from '../components/Title';
import { ROUTES } from '../constants/routes';
import { IReporteAcceso } from '../models/interfaces/accesos/IAccesoReporte';

export default function Reportes() {
  const [fechas, setFechas] = useState<{ fechaInicio: string; fechaTermino: string }>({
    fechaInicio: new Date().toISOString().slice(0, 10),
    fechaTermino: new Date().toISOString().slice(0, 10),
  });
  const [listaAccesos, setListaAccesos] = useState<IReporteAcceso[]>([]);

  const obtenerAccesosReporte = () => {
    try {
      setListaAccesos([]);
    } catch (ex) {
      console.log(ex);
    }
  };

  useEffect(() => {
    obtenerAccesosReporte();
  }, []);

  return (
    <Container className="d-flex flex-column">
      <Title titulo="Reportes de Acceso" ruta={ROUTES.ACCESO} />
      <Row className="my-4">
        <Col xs={12} sm={12} md={4} lg={4}>
          <Form.Group>
            <Form.Label>Área Acceso</Form.Label>
            <Form.Control as="select" name="acceso">
              <option value="">Seleccione Acceso</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4}>
          <Form.Group>
            <Form.Label>Fecha Inicio</Form.Label>
            <Form.Control
              type="date"
              value={fechas.fechaInicio}
              onChange={({ target }) => setFechas(fechas => ({ ...fechas, fechaInicio: target.value }))}
              name="fechaInicio"
            />
          </Form.Group>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4}>
          <Form.Group>
            <Form.Label>Fecha Termino</Form.Label>
            <Form.Control
              type="date"
              value={fechas.fechaTermino}
              onChange={({ target }) => setFechas(fechas => ({ ...fechas, fechaTermino: target.value }))}
              name="fechaTermino"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="my-4">
        <Col xs={12} sm={12} md={6} lg={6}>
          <Form.Group>
            <Form.Label>Nombre Alumno</Form.Label>
            <Form.Control type="search" name="nombreAlumno" placeholder="Nombre Alumno" />
          </Form.Group>
        </Col>
      </Row>
      <hr />
      <Row className="my-4">
        <Col className="d-flex align-items-center justify-content-end" xs={12} sm={12} md={12} lg={12}>
          <>
            <Button variant="secondary" className="text-white mx-3">
              Exportar Excel
            </Button>
            <Button variant="outline-dark">Imprimir</Button>
          </>
        </Col>
      </Row>
      <Row className="my-4">
        <Col xs={12} sm={12} md={12} lg={12}>
          <Table striped hover>
            <thead>
              <tr>
                <th>Alumno</th>
                <th>Accesos</th>
                <th>Tipo</th>
                <th>Fecha/Hora</th>
              </tr>
            </thead>
            <tbody>
              {listaAccesos.length > 0 ? (
                <>
                  {listaAccesos.map((acceso: IReporteAcceso) => (
                    <tr key={acceso.id}>
                      <td>{acceso.alumno}</td>
                      <td>{acceso.acceso}</td>
                      <td>{acceso.tipo}</td>
                      <td>{acceso.fechaHora}</td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={4}>No hay accesos registrados</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}
