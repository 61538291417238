import { useState, useEffect } from 'react';
import { Container, Row, Col, Tabs, Tab, Form } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';

import { ROUTES } from '../constants/routes';
import { ICargando } from '../models/interfaces/ICargando';
import { addAlumnoService, editAlumnoService, getAlumnoService } from '../services/alumnos.service';
import { IRegistrarAlumno } from '../models/interfaces/alumnos/IRegistroAlumno';
import { registroAlumnoValidationSchema } from '../utils/validations/registro-alumno.validation';
import Title from '../components/Title';
import FormularioGeneral from '../components/Alumnos/FormularioGeneral';
// import FormularioMedico from '../components/Alumnos/FormularioMedico';
import useError from '../hooks/useError';
import Loading from '../components/Loading';

export default function AgregarEstudiante() {
  const [keyTab, setKeyTab] = useState('generales');
  const [cargando, setCargando] = useState<ICargando>({
    cargando: false,
    mensaje: '',
  });

  const { id, idAlumno } = useParams();
  const navigate = useNavigate();
  const { setError, ErrorAlert } = useError();
  const initialValues: IRegistrarAlumno = {
    foto: '',
    padreTutorId: 0,
    nombres: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    nivelEscolar: '',
    gradoGrupo: '',
    tarjetaId: 0,
    fechaNacimiento: new Date().toISOString().slice(0, 10),
    sexo: 1,
    telefono: '',
    domicilio: '',
    correo: '',
    tipoDeSangre: '',
    discapacidad: false,
    descripcionDiscapacidad: '',
    alergias: false,
    descripcionAlergias: '',
    medicamentosControlados: false,
    descripcionMedicamentosControlados: '',
    enfermedadesCronicas: false,
    descripcionEnfermedadesCronicas: '',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values: IRegistrarAlumno) => guardarAlumno({ ...values, padreTutorId: +id }),
    validationSchema: registroAlumnoValidationSchema,
  });

  const guardarAlumno = async (alumnoRequest: IRegistrarAlumno) => {
    try {
      setCargando({
        cargando: true,
        mensaje: idAlumno ? 'Editando Alumno' : 'Guardando Alumno',
      });
      !!idAlumno
        ? await editAlumnoService(
            {
              ...alumnoRequest,
              alergias: !!alumnoRequest.alergias,
              discapacidad: !!alumnoRequest.discapacidad,
              enfermedadesCronicas: !!alumnoRequest.enfermedadesCronicas,
              medicamentosControlados: !!alumnoRequest.medicamentosControlados,
            },
            +idAlumno
          )
        : await addAlumnoService({
            ...alumnoRequest,
            alergias: !!alumnoRequest.alergias,
            discapacidad: !!alumnoRequest.discapacidad,
            enfermedadesCronicas: !!alumnoRequest.enfermedadesCronicas,
            medicamentosControlados: !!alumnoRequest.medicamentosControlados,
          });
      navigate(`${ROUTES.TUTORES_PUPILOS}/${id}`);
    } catch (ex) {
      setError(ex);
      window.scrollTo(0, 0);
    } finally {
      setCargando({
        cargando: false,
        mensaje: '',
      });
    }
  };

  const establecerDatosAlumno = async () => {
    try {
      setCargando({
        cargando: true,
        mensaje: 'Cargando datos del alumno...',
      });
      const response = await getAlumnoService(+idAlumno);
      formik.setFieldValue('foto', response.foto ?? '');
      formik.setFieldValue('gradoGrupo', response.gradoGrupo ?? '');
      formik.setFieldValue('nivelEscolar', response.nivelEscolar ?? '');
      formik.setFieldValue('tarjetaId', response.tarjetaId ?? 0);
      formik.setFieldValue('apellidoPaterno', response.apellidoPaterno);
      formik.setFieldValue('apellidoMaterno', response.apellidoMaterno);
      formik.setFieldValue('nombres', response.nombres);
      formik.setFieldValue('fechaNacimiento', new Date(response.fechaNacimiento).toISOString().slice(0, 10));
      formik.setFieldValue('sexo', response.sexo);
      formik.setFieldValue('telefono', response.telefono ?? '');
      formik.setFieldValue('domicilio', response.domicilio ?? '');
      formik.setFieldValue('correo', response.correo);
      formik.setFieldValue('tipoDeSangre', response.tipoDeSangre ?? '');
      formik.setFieldValue('discapacidad', response.discapacidad);
      formik.setFieldValue('descripcionDiscapacidad', response.descripcionDiscapacidad ?? '');
      formik.setFieldValue('alergias', response.alergias);
      formik.setFieldValue('descripcionAlergias', response.descripcionAlergias ?? '');
      formik.setFieldValue('medicamentosControlados', response.medicamentosControlados);
      formik.setFieldValue('descripcionMedicamentosControlados', response.descripcionMedicamentosControlados ?? '');
      formik.setFieldValue('enfermedadesCronicas', response.enfermedadesCronicas);
      formik.setFieldValue('descripcionEnfermedadesCronicas', response.descripcionEnfermedadesCronicas ?? '');
    } catch (ex) {
      setError(ex);
    } finally {
      setCargando({
        cargando: false,
        mensaje: '',
      });
    }
  };

  useEffect(() => {
    if (Object.keys(formik.errors).length && keyTab === 'medicos') {
      setError(new Error('No se completaron todos los campos requeridos en datos generales.'));
      window.scrollTo(0, 0);
    }
  }, [formik.errors]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!!idAlumno) {
      establecerDatosAlumno();
    }

    //i Se limpian los errores de formik al entrar al formulario
    setTimeout(() => {
      formik.setErrors({});
    }, 20);

    return () => {
      setKeyTab('generales');
      setError('');
      setCargando({
        cargando: false,
        mensaje: '',
      });
      formik.resetForm();
    };
  }, []);

  return (
    <Container className="d-flex flex-column">
      {cargando.cargando && <Loading mensaje={cargando.mensaje} />}
      <ErrorAlert />
      <Title titulo="Registro Alumno" ruta={`${ROUTES.TUTORES_PUPILOS}/${id}`} />
      <Row className="my-4">
        <Form onSubmit={formik.handleSubmit}>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Tabs activeKey={keyTab} id="uncontrolled-tab-example" onSelect={keyActive => setKeyTab(keyActive)}>
              <Tab className="p-3" title="Datos Generales" eventKey="generales">
                <FormularioGeneral
                  formik={formik}
                  error={setError}
                  domicilioFormateado={!!idAlumno ? formik.values.domicilio : ''}
                />
              </Tab>
              {/* <Tab className="p-3" title="Datos Médicos" eventKey="medicos">
                <FormularioMedico formik={formik} error={setError} />
              </Tab> */}
            </Tabs>
          </Col>
        </Form>
      </Row>
    </Container>
  );
}
