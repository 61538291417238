import { useState, useEffect, useRef } from 'react';
import { Modal, Form, Button, Row, Col, Spinner } from 'react-bootstrap';

import { EliminarUsuarioProps } from '../../models/types/modals/eliminar-usuario.type';

export default function EliminarUsuarioModal(props: EliminarUsuarioProps) {
  const { estado, cambiarEstado, recuperarUsuario, contenido } = props;
  const [nombreUsuario, setNombreUsuario] = useState<string>('');
  const [cargando, setCargando] = useState<boolean>(false);
  const refNombreUsuario = useRef(null);

  const eliminandoUsuario = () => {
    setCargando(true);
    recuperarUsuario(contenido.id);
  };

  const validandoUsuario = () => {
    if (nombreUsuario !== contenido.userName) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!!!estado) {
      setNombreUsuario('');
      setCargando(false);
    } else {
      refNombreUsuario.current.focus();
    }
  }, [estado]);

  return (
    <Modal show={estado} onHide={() => cambiarEstado(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Eliminar Usuario</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6 className="fw-normal">
          Para eliminiar el usuario <span className="fw-bold">{contenido.userName}</span> escribalo en el campo.
        </h6>
        <Row className="my-3">
          <Col xs={12} sm={12} md={12} lg={12}>
            <Form.Group>
              <Form.Control
                ref={refNombreUsuario}
                name="nombreUsuario"
                value={nombreUsuario}
                onChange={({ target }) => setNombreUsuario(target.value)}
                placeholder="Usuario"
                disabled={cargando}
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => cambiarEstado(false)}>
          Cancelar
        </Button>
        <Button
          className="text-white"
          variant="danger"
          onClick={() => eliminandoUsuario()}
          disabled={validandoUsuario() || cargando}
        >
          {cargando ? (
            <>
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              &nbsp;Eliminando
            </>
          ) : (
            <>Eliminar</>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
