import { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Form, Card, Spinner, Image } from 'react-bootstrap';

import { SEXO } from '../../models/enums/sexo.enum';
import placeholderImage from '../../assets/images/placeholder.svg';
import { formularioGeneralProps } from '../../models/types/alumnos/formulario-general.type';
import { convertirFotoBase64 } from '../../utils/conversionBase64';
import { buscarColoniasService } from '../../services/localidades.service';
import { getCatalogosService } from '../../services/catalogos.service';
import { ICatalogo, INivelEscolarCatalogo, IGradoGrupoCatalogo } from '../../models/interfaces/catalogos/ICatalogo';
import Loading from '../../components/Loading';

export default function FormularioGeneral(props: formularioGeneralProps) {
  const { formik, error, domicilioFormateado } = props;
  const [cargando, setCargando] = useState<boolean>(false);
  const [domicilio, setDomicilio] = useState<{
    codigoPostal: string;
    colonia: string;
    calle: string;
    numeroExterior: string;
    listaColonias: string[];
  }>({
    codigoPostal: '',
    colonia: '',
    calle: '',
    numeroExterior: '',
    listaColonias: [],
  });
  const [catalogos, setCatalogos] = useState<ICatalogo>({
    nivelesEscolares: [],
    gradosGrupos: [],
  });
  const fotoRef = useRef(null);

  const abrirExploradorDocumentos = () => {
    if (!!fotoRef) {
      fotoRef.current.click();
    }
  };

  //i Se detecta la carga de foto y se válida
  const leerImagen = async () => {
    try {
      if (!!fotoRef?.current.files) {
        setCargando(true);
        const [imagenCargada] = fotoRef.current.files;
        const fotoString: any = await convertirFotoBase64(imagenCargada);
        document.querySelector('#img-foto-estudiante')?.setAttribute('src', fotoString);
        formik.setFieldValue('foto', fotoString.split(',')[1]); //i Se manda el base64 sin 'data:image/*;base64'
      }
    } catch (ex) {
      new Error(ex);
    } finally {
      setCargando(false);
    }
  };

  const obtenerColonias = async () => {
    try {
      setCargando(true);
      const response = await buscarColoniasService(domicilio.codigoPostal);
      if (response) {
        setDomicilio(dom => ({ ...dom, listaColonias: response.map(col => col.nombreColonia) }));
      } else {
        throw new Error('No se encontraron colonias');
      }
    } catch (ex) {
      error(new Error(ex));
    } finally {
      setCargando(false);
    }
  };

  const obtenerCatalogos = async () => {
    try {
      const response = await getCatalogosService();
      setCatalogos(response);
    } catch (ex) {
      error(ex);
    }
  };

  const formatoDomicilio = () =>
    `${domicilio.codigoPostal}$${domicilio.colonia}$${domicilio.calle}$${domicilio.numeroExterior}`;

  useEffect(() => {
    if (domicilio.codigoPostal.length > 4) {
      obtenerColonias();
    } else if (domicilio.codigoPostal.length < 5) {
      setDomicilio(dom => ({
        ...dom,
        colonia: '',
        listaColonias: [],
      }));
    }
  }, [domicilio.codigoPostal]);

  useEffect(() => {
    formik.setFieldValue('domicilio', formatoDomicilio());
  }, [domicilio.colonia, domicilio.calle, domicilio.numeroExterior]);

  useEffect(() => {
    if (domicilioFormateado.length && !!!domicilio.listaColonias.length) {
      const [codigoPostal, colonia, calle, numeroExterior] = domicilioFormateado.split('$');
      setDomicilio(dom => ({
        ...dom,
        codigoPostal,
        colonia,
        calle,
        numeroExterior,
      }));
    }
  }, [domicilioFormateado]);

  useEffect(() => {
    obtenerCatalogos();
    return () => {
      setCargando(false);
      setDomicilio({
        codigoPostal: '',
        colonia: '',
        calle: '',
        numeroExterior: '',
        listaColonias: [],
      });
    };
  }, []);

  return (
    <>
      {cargando && domicilio.codigoPostal.length > 4 && <Loading mensaje="Cargando colonias..." />}
      <Row className="my-4">
        <Col xs={4} sm={4} md={4} lg={4}>
          <div
            className="d-flex align-items-center justify-content-between flex-column p-3"
            style={formik.errors.foto && { border: 'solid 2px #dc3545', borderRadius: '5px' }}
          >
            <Image
              className="mb-4"
              id="img-foto-estudiante"
              style={{ objectFit: 'scale-down', borderRadius: '12px' }}
              src={!!formik.values.foto ? `data:image/png;base64,${formik.values.foto}` : placeholderImage}
              width={220}
              height={220}
              alt="Foto Estudiante"
            />
            <Button
              variant="secondary"
              className="text-white"
              disabled={cargando}
              onClick={() => abrirExploradorDocumentos()}
            >
              {cargando ? (
                <>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  &nbsp;Cargando Foto...
                </>
              ) : (
                'Seleccionar Foto'
              )}
            </Button>
          </div>
          <Form.Group>
            <Form.Control
              ref={fotoRef}
              type="file"
              accept="image/png, image/gif, image/jpeg"
              name="foto"
              isInvalid={!!formik.errors.foto}
              onChange={() => leerImagen()}
              className="d-none"
            />
            <Form.Control.Feedback className="d-block" type="invalid">
              {formik.errors.foto}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xs={8} sm={8} md={8} lg={8}>
          <Card>
            <Card.Header>
              <Card.Title>PROGRAMA EDUCATIVO</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col xs={12} sm={4} md={4} lg={4}>
                  <Form.Group>
                    <Form.Label>Grado/Grupo</Form.Label>
                    <Form.Control
                      as="select"
                      isInvalid={!!formik.errors.gradoGrupo}
                      value={formik.values.gradoGrupo}
                      onChange={formik.handleChange}
                      name="gradoGrupo"
                      placeholder="Grado/Grupo"
                    >
                      <option value="">Selecciona Grado/Grupo</option>
                      {catalogos.gradosGrupos.map((grado: IGradoGrupoCatalogo) => (
                        <option key={grado.id} value={grado.gradoGrupo}>
                          {grado.gradoGrupo}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback className="d-block" type="invalid">
                      {formik.errors.gradoGrupo}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={4} md={4} lg={4}>
                  <Form.Group>
                    <Form.Label>Nivel</Form.Label>
                    <Form.Control
                      as="select"
                      isInvalid={!!formik.errors.nivelEscolar}
                      value={formik.values.nivelEscolar}
                      onChange={formik.handleChange}
                      name="nivelEscolar"
                    >
                      <option value="">Selecciona Nivel</option>
                      {catalogos.nivelesEscolares.map((niv: INivelEscolarCatalogo) => (
                        <option key={niv.id} value={niv.nivelEscolar}>
                          {niv.nivelEscolar}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback className="d-block" type="invalid">
                      {formik.errors.nivelEscolar}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={4} md={4} lg={4}>
                  <Form.Group>
                    <Form.Label>Número Tarjeta</Form.Label>
                    <Form.Control
                      type="number"
                      isInvalid={!!formik.errors.tarjetaId}
                      value={formik.values.tarjetaId}
                      onChange={formik.handleChange}
                      name="tarjetaId"
                    />
                    <Form.Control.Feedback className="d-block" type="invalid">
                      {formik.errors.tarjetaId}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="my-4">
        <Col xs={12} sm={12} md={12} lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>DATOS GENERALES</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row className="my-3">
                <Col xs={12} sm={12} md={4} lg={4}>
                  <Form.Group>
                    <Form.Label>Apellido Paterno</Form.Label>
                    <Form.Control
                      name="apellidoPaterno"
                      isInvalid={!!formik.errors.apellidoPaterno}
                      value={formik.values.apellidoPaterno}
                      onChange={formik.handleChange}
                      placeholder="Apellido Paterno"
                    />
                    <Form.Control.Feedback className="d-block" type="invalid">
                      {formik.errors.apellidoPaterno}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4}>
                  <Form.Group>
                    <Form.Label>Apellido Materno</Form.Label>
                    <Form.Control
                      name="apellidoMaterno"
                      isInvalid={!!formik.errors.apellidoMaterno}
                      value={formik.values.apellidoMaterno}
                      onChange={formik.handleChange}
                      placeholder="Apellido Materno"
                    />
                    <Form.Control.Feedback className="d-block" type="invalid">
                      {formik.errors.apellidoMaterno}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4}>
                  <Form.Group>
                    <Form.Label>Nombre(s)</Form.Label>
                    <Form.Control
                      name="nombres"
                      isInvalid={!!formik.errors.nombres}
                      value={formik.values.nombres}
                      onChange={formik.handleChange}
                      placeholder="Nombre(s)"
                    />
                    <Form.Control.Feedback className="d-block" type="invalid">
                      {formik.errors.nombres}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="my-3">
                <Col xs={12} sm={12} md={4} lg={4}>
                  <Form.Group>
                    <Form.Label>Fecha Nacimiento</Form.Label>
                    <Form.Control
                      name="fechaNacimiento"
                      isInvalid={!!formik.errors.fechaNacimiento}
                      value={formik.values.fechaNacimiento}
                      onChange={formik.handleChange}
                      type="date"
                    />
                    <Form.Control.Feedback className="d-block" type="invalid">
                      {formik.errors.fechaNacimiento}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4}>
                  <Form.Group>
                    <Form.Label>Sexo</Form.Label>
                    <Form className="d-flex aling-items-center justify-content-around pt-2">
                      <Form.Check
                        inline
                        id="h"
                        name="sexo"
                        isInvalid={!!formik.errors.sexo}
                        value={SEXO.HOMBRE}
                        onChange={({ target }) => formik.setFieldValue('sexo', +target.value)}
                        type="radio"
                        label="H"
                        checked={formik.values.sexo === SEXO.HOMBRE}
                      />
                      <Form.Check
                        inline
                        id="m"
                        name="sexo"
                        isInvalid={!!formik.errors.sexo}
                        value={SEXO.MUJER}
                        onChange={({ target }) => formik.setFieldValue('sexo', +target.value)}
                        type="radio"
                        label="M"
                        checked={formik.values.sexo === SEXO.MUJER}
                      />
                      <Form.Check
                        inline
                        id="o"
                        name="sexo"
                        isInvalid={!!formik.errors.sexo}
                        value={SEXO.OTRO}
                        onChange={({ target }) => formik.setFieldValue('sexo', +target.value)}
                        type="radio"
                        label="Otro"
                        checked={formik.values.sexo === SEXO.OTRO}
                      />
                    </Form>
                    <Form.Control.Feedback className="d-block" type="invalid">
                      {formik.errors.sexo}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4}>
                  <Form.Group>
                    <Form.Label>Código Postal</Form.Label>
                    <Form.Control
                      name="codigoPostal"
                      value={domicilio.codigoPostal}
                      onChange={({ target }) => setDomicilio(dom => ({ ...dom, codigoPostal: target.value }))}
                      placeholder="Código Postal"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={4} lg={4}>
                  <Form.Group>
                    <Form.Label>Colonia</Form.Label>
                    <Form.Control
                      name="colonia"
                      value={domicilio.colonia}
                      onChange={({ target }) => setDomicilio(dom => ({ ...dom, colonia: target.value }))}
                      as="select"
                    >
                      <option value="">Seleccionar Colonia</option>
                      {domicilio.listaColonias.map((col: string) => (
                        <option key={col} value={col}>
                          {col}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4}>
                  <Form.Group>
                    <Form.Label>Calle</Form.Label>
                    <Form.Control
                      name="calle"
                      value={domicilio.calle}
                      onChange={({ target }) => setDomicilio(dom => ({ ...dom, calle: target.value }))}
                      placeholder="Calle"
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4}>
                  <Form.Group>
                    <Form.Label>No.Exterior</Form.Label>
                    <Form.Control
                      name="noExterior"
                      value={domicilio.numeroExterior}
                      onChange={({ target }) => setDomicilio(dom => ({ ...dom, numeroExterior: target.value }))}
                      placeholder="No.Exterior"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="my-4">
        <Col xs={12} sm={12} md={12} lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>CONTACTO</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row className="my-3">
                <Col xs={12} sm={12} md={5} lg={5}>
                  <Form.Group>
                    <Form.Label>Teléfono</Form.Label>
                    <Form.Control
                      name="telefono"
                      isInvalid={!!formik.errors.telefono}
                      value={formik.values.telefono}
                      onChange={formik.handleChange}
                      placeholder="Teléfono"
                    />
                    <Form.Control.Feedback className="d-block" type="invalid">
                      {formik.errors.telefono}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={12} md={7} lg={7}>
                  <Form.Group>
                    <Form.Label>Correo</Form.Label>
                    <Form.Control
                      name="correo"
                      isInvalid={!!formik.errors.correo}
                      value={formik.values.correo}
                      onChange={formik.handleChange}
                      placeholder="Correo"
                    />
                    <Form.Control.Feedback className="d-block" type="invalid">
                      {formik.errors.correo}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="my-4">
        <Col className="d-flex align-items-center justify-content-end" xs={12} sm={12} md={12} lg={12}>
          <Button variant="secondary" className="text-white" type="submit">
            {!!formik.values.foto ? 'Editar Alumno' : 'Guardar Alumno'}
          </Button>
        </Col>
      </Row>
    </>
  );
}
