import { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import * as signalR from '@microsoft/signalr';

import Title from '../components/Title';
import ItemAcceso from '../components/Acceso/Item';
import Estudiantes from '../assets/images/icons/estudiantes.png';
import Monitoreo from '../assets/images/icons/monitoreo.png';
import Tutores from '../assets/images/icons/tutores.png';
import AvatarPlaceholderImage from '../assets/images/avatar-placeholder.gif';
import { ROUTES } from '../constants/routes';
import { ILectora } from '../models/interfaces/ILectora';
import { ICargando } from '../models/interfaces/ICargando';
import { IAccesoEntrada } from '../models/interfaces/accesos/IAccesoEntrada';
import { getLectoras, getUltimosAccesosService } from '../services/acceso.service';
import { TIPO_ACCESO, TIPO_ACCESO_TEXTO } from '../models/enums/tipoAccesos.enum';
import Loading from '../components/Loading';
import useError from '../hooks/useError';
import useSesion from '../hooks/useSesion';

import '../styles/Acceso.scss';

export default function Principal() {
  const [conexionSignalR, setConexionSignalR] = useState<any>(null);
  const [cargando, setCargando] = useState<ICargando>({
    cargando: false,
    mensaje: '',
  });
  const [lectoras, setLectoras] = useState<ILectora[]>([]);
  const [entrada, setEntrada] = useState<IAccesoEntrada>({
    tarjetaId: 0,
    lectoraId: 0,
    tipoAcceso: 0,
    foto: '',
    nombreCompleto: '',
    gradoGrupo: '',
    nivelEscolar: '',
    descripcionTipoAcceso: '',
  });
  const { setError } = useError();
  const { dataUser } = useSesion();

  //i Se pasa el rol, si existe ese role entonces podemos condicionar que items mostrar o cuales no en el navbar
  const esRole = (role: string) => dataUser.roles.map((rol: string) => rol.toLowerCase()).includes(role.toLowerCase());

  //i Se cambian los valores de las tarjetas de las lectoras
  const inyectarDatos = (dataAcceso: IAccesoEntrada = entrada) => {
    if (document.querySelector(`#lectora-${dataAcceso.lectoraId}`)) {
      const elementos = {
        foto: document.querySelector(`#foto-${dataAcceso.lectoraId}`),
        tarjeta: document.querySelector(`#tarjeta-${dataAcceso.lectoraId}`),
        nombre: document.querySelector(`#nombre-${dataAcceso.lectoraId}`),
        escuela: document.querySelector(`#escuela-${dataAcceso.lectoraId}`),
        estado: document.querySelector(`#estado-${dataAcceso.lectoraId}`),
      };

      elementos.foto?.setAttribute('src', `data:image/png;base64,${dataAcceso.foto}`);
      elementos.tarjeta.textContent = `No.Tarjeta: ${dataAcceso.tarjetaId}`;
      elementos.nombre.textContent = dataAcceso.nombreCompleto;
      elementos.escuela.textContent = `${dataAcceso.nivelEscolar} - ${dataAcceso.gradoGrupo}`;
      elementos.estado.textContent = TIPO_ACCESO[dataAcceso.tipoAcceso];
      elementos.estado.setAttribute(
        'class',
        `acceso__estado--${TIPO_ACCESO_TEXTO[`${TIPO_ACCESO[dataAcceso.tipoAcceso]}_TEXTO`].toLowerCase()}`
      );
    }
  };

  const obtenerUltimosAccesos = async () => {
    try {
      setCargando({
        cargando: true,
        mensaje: 'Obteniendo últimos accesos',
      });
      const response: IAccesoEntrada[] = await getUltimosAccesosService();
      if (response.length) {
        response.map((acc: IAccesoEntrada) => {
          inyectarDatos(acc);
        });
      }
    } catch (ex) {
      setError(ex);
    } finally {
      setCargando({
        cargando: false,
        mensaje: '',
      });
    }
  };

  const obtenerLectoras = async () => {
    try {
      const response = await getLectoras();
      setLectoras(response);
    } catch (ex) {
      setError(ex);
    }
  };

  //i Escuchar el socket cada que la lectora se use
  const listenSocket = () => {
    if (conexionSignalR) {
      conexionSignalR
        .start()
        .then(() => {
          conexionSignalR.on('IngresoSalida', response => {
            if (response) {
              setEntrada(response);
            }
          });
        })
        .catch(error => error);
    }
  };

  //i Conexión a SignalR
  const obtenerEntradaSalidaRealTime = () => {
    const conexion = new signalR.HubConnectionBuilder()
      .withUrl('/IngresoSalidaHub', {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();
    setConexionSignalR(conexion);
  };

  useEffect(() => {
    //i Se se obtiene información de la lectora, se inyectan los datos
    //i en la tarjeta correspondietne a la lectora
    if (!!entrada.tarjetaId) {
      inyectarDatos();
    }
  }, [entrada]);

  useEffect(() => {
    listenSocket();
    return () => {
      if (conexionSignalR) {
        conexionSignalR.off('Acceso Actualizado');
        conexionSignalR.stop();
        setEntrada({
          tarjetaId: 0,
          lectoraId: 0,
          tipoAcceso: 0,
          foto: '',
          nombreCompleto: '',
          gradoGrupo: '',
          nivelEscolar: '',
          descripcionTipoAcceso: '',
        });
      }
    };
  }, [conexionSignalR]);

  useEffect(() => {
    if (esRole('Display')) {
      obtenerLectoras();
      obtenerUltimosAccesos();
      obtenerEntradaSalidaRealTime();
    }
    return () => {
      if (conexionSignalR) {
        conexionSignalR.off('Acceso Actualizado');
        conexionSignalR.stop();
        setEntrada({
          tarjetaId: 0,
          lectoraId: 0,
          tipoAcceso: 0,
          foto: '',
          nombreCompleto: '',
          gradoGrupo: '',
          nivelEscolar: '',
          descripcionTipoAcceso: '',
        });
      }
    };
  }, []);

  return (
    <Container className="acceso d-flex flex-column">
      {cargando.cargando && <Loading mensaje={cargando.mensaje} />}
      <Title titulo="Acceso Escolar" />
      <>
        {dataUser?.roles.map((role: string) => role.toLowerCase()).includes('display') ? (
          <>
            <Row className="mt-2">
              <Col xs={12} sm={12} md={12} lg={12}>
                <h4 className="text-center">{format(new Date(), 'eee dd MMMM yyyy')}</h4>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="acceso__contenedor-tarjeta" xs={12} sm={12} md={12} lg={12}>
                {lectoras.map((lectora: ILectora) => (
                  <Card key={lectora.id} className="acceso__tarjeta my-3" id={`lectora-${lectora.id}`}>
                    <Card.Body className="d-flex algin-items-center justify-content-center flex-column">
                      <h4 className="acceso__titulo-tarjeta text-center mb-3">Lectora Acceso {lectora.id}</h4>
                      <img
                        className="acceso__foto"
                        id={`foto-${lectora.id}`}
                        src={AvatarPlaceholderImage}
                        alt="Avatar Usuario"
                      />
                      <h5 className="acceso__nombre-tarjeta text-center mt-3" id={`nombre-${lectora.id}`}>
                        Nombre Alumno
                      </h5>
                      <p className="text-center" id={`escuela-${lectora.id}`}>
                        Nivel - Grado/Grupo
                      </p>
                      <p className="fw-bold text-center" id={`tarjeta-${lectora.id}`}>
                        No.Tarjeta: ----
                      </p>
                      <div className="acceso__estado" id={`estado-${lectora.id}`}>
                        -----
                      </div>
                    </Card.Body>
                  </Card>
                ))}
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row className="mt-5">
              <Col className="acceso__tarjetas offset-lg-1 offset-md-1" xs={12} sm={6} md={5} lg={5}>
                <Link to={ROUTES.ESTUDIANTES}>
                  <ItemAcceso titulo="Estudiantes" imagen={Estudiantes} />
                </Link>
              </Col>
              <Col className="acceso__tarjetas" xs={12} sm={6} md={5} lg={5}>
                <Link to={ROUTES.TUTORES}>
                  <ItemAcceso titulo="Tutores" imagen={Tutores} />
                </Link>
              </Col>
              <Col className="acceso__tarjetas offset-lg-1 offset-md-1" xs={12} sm={6} md={5} lg={5}>
                <Link to={ROUTES.MONITOREO}>
                  <ItemAcceso titulo="Monitoreo" imagen={Monitoreo} />
                </Link>
              </Col>
              {/* <Col className="acceso__tarjetas" xs={12} sm={6} md={5} lg={5}>
                <Link to={ROUTES.REPORTES}>
                  <ItemAcceso titulo="Reportes" imagen={Reportes} />
                </Link>
              </Col> */}
            </Row>
          </>
        )}
      </>
    </Container>
  );
}
