import { IRegistroUsuario } from '../models/interfaces/usuario/IRegistroUsuario';
import http from '../utils/http';

export const getUsuariosService = async () => {
  try {
    const response = await http.get('Usuario/GetUsers');
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const addUsuariosService = async (dataRequest: IRegistroUsuario) => {
  try {
    const response = await http.post('Usuario/registraUsuario', dataRequest);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const editUsuariosService = async (dataRequest: IRegistroUsuario, idUser: string) => {
  try {
    const response = await http.put(`Usuario/ActualizaUsuario/${idUser}`, dataRequest);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const deleteUsuariosService = async (idUser: string) => {
  try {
    const response = await http.delete(`Usuario/EliminaUsuario/${idUser}`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};
