import { gradosProps } from '../../models/types/monitor/grados.type';
import { IAccesoGradoGrupo } from '../../models/interfaces/accesos/IAccesoAsistencia';

export default function Grados(props: gradosProps) {
  const { gradosNivel } = props;

  return (
    <>
      {gradosNivel?.map((acceso: IAccesoGradoGrupo, key) => (
        <div className="d-flex align-items-center justify-content-center flex-column" key={key}>
          <h4 className="fw-bold">{acceso.gradoGrupo}º</h4>
          <h4>
            {acceso.cantidadEntrada}/<span className="fw-bold">{acceso.totalAlumnos}</span>
          </h4>
        </div>
      ))}
    </>
  );
}
