import { useState, useEffect } from 'react';
import { Container, Row, Col, Form, InputGroup, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import DescriptionIcon from '@mui/icons-material/Description';

import Title from '../components/Title';
import Loading from '../components/Loading';
import { ROUTES } from '../constants/routes';
import { IAlumnoDetalle } from '../models/interfaces/alumnos/IAlumno';
import { ICargando } from '../models/interfaces/ICargando';
import { ICatalogo, IGradoGrupoCatalogo, INivelEscolarCatalogo } from '../models/interfaces/catalogos/ICatalogo';
import { getCatalogosService } from '../services/catalogos.service';
import { getAlumnosService, getAlumnosBusquedaService, getAlumnosFiltrosService } from '../services/alumnos.service';
import placeholderImage from '../assets/images/placeholder.png';
import useError from '../hooks/useError';

import '../styles/Estudiantes.scss';

export default function Estudiantes() {
  const [itemBusqueda, setItemBusqueda] = useState<{ busqueda: string; nivel: string; gradoGrupo: string }>({
    busqueda: '',
    nivel: '',
    gradoGrupo: '',
  });
  const [estudiantes, setEstudiantes] = useState<IAlumnoDetalle[]>([]);
  const [catalogos, setCatalogos] = useState<ICatalogo>({
    nivelesEscolares: [],
    gradosGrupos: [],
  });
  const [cargando, setCargando] = useState<ICargando>({
    cargando: false,
    mensaje: '',
  });
  const { setError, ErrorAlert } = useError();
  const navigate = useNavigate();

  const obtenerEstudiantes = async () => {
    try {
      setCargando({
        cargando: true,
        mensaje: 'Obteniendo alumnos...',
      });
      const response = await getAlumnosService();
      await setEstudiantes(response);
      obtenerFiltros();
    } catch (ex) {
      setError(ex);
    } finally {
      setCargando({
        cargando: false,
        mensaje: '',
      });
    }
  };

  const obtenerFiltros = async () => {
    try {
      const response = await getCatalogosService();
      setCatalogos(response);
    } catch (ex) {
      setError(ex);
    }
  };

  const obtenerAlumnosFiltrados = async () => {
    try {
      //i Si se cambia un filtro de tipo select se inhabilita el campo de busqueda
      //i en caso contrario se inhabilitan los select y se llama el endpoint con filtros correspondientes
      if (!!itemBusqueda.nivel || !!itemBusqueda.gradoGrupo) {
        setCargando({
          cargando: true,
          mensaje: 'Buscando alumnos...',
        });
        const response = await getAlumnosFiltrosService(itemBusqueda.nivel, itemBusqueda.gradoGrupo);
        setEstudiantes(response);
      } else if (!!itemBusqueda.busqueda) {
        setCargando({
          cargando: true,
          mensaje: 'Buscando alumnos...',
        });
        const response = await getAlumnosBusquedaService(itemBusqueda.busqueda);
        setEstudiantes(response);
      } else {
        obtenerEstudiantes();
      }
    } catch (ex) {
      setError(ex);
    } finally {
      setCargando({
        cargando: false,
        mensaje: '',
      });
    }
  };

  useEffect(() => {
    obtenerAlumnosFiltrados();
  }, [itemBusqueda]);

  useEffect(() => {
    obtenerEstudiantes();
    return () => {
      setItemBusqueda({
        busqueda: '',
        nivel: '',
        gradoGrupo: '',
      });
      setEstudiantes([]);
      setCatalogos({
        nivelesEscolares: [],
        gradosGrupos: [],
      });
      setCargando({
        cargando: false,
        mensaje: '',
      });
    };
  }, []);

  return (
    <Container className="estudiantes d-flex flex-column">
      <ErrorAlert />
      {cargando.cargando && <Loading mensaje={cargando.mensaje} />}
      <Title titulo="Estudiantes" ruta={ROUTES.ACCESO} />
      <Row className="my-4">
        <Col className="my-3 d-flex align-items-end justify-content-between" xs={12} sm={6} md={6} lg={6}>
          <InputGroup>
            <Form.Control
              name="buscar"
              value={itemBusqueda.busqueda}
              onChange={({ target }) => setItemBusqueda(items => ({ ...items, busqueda: target.value }))}
              placeholder="Nombre Completo"
              disabled={!!itemBusqueda.nivel || !!itemBusqueda.gradoGrupo}
            />
            <InputGroup.Text id="search">
              <SearchIcon />
            </InputGroup.Text>
          </InputGroup>
        </Col>
        <Col className="my-3" xs={6} sm={3} md={3} lg={3}>
          <Form.Group>
            <Form.Label>Nivel Escolar</Form.Label>
            <Form.Control
              as="select"
              name="nivelEscolar"
              value={itemBusqueda.nivel}
              onChange={({ target }) => setItemBusqueda(items => ({ ...items, nivel: target.value }))}
              disabled={!!itemBusqueda.busqueda}
            >
              <option value="">Selecciona un nivel escolar</option>
              {catalogos.nivelesEscolares.map((niv: INivelEscolarCatalogo) => (
                <option key={niv.id} value={niv.nivelEscolar}>
                  {niv.nivelEscolar}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col className="my-3" xs={6} sm={3} md={3} lg={3}>
          <Form.Group>
            <Form.Label>Grado/Grupo</Form.Label>
            <Form.Control
              as="select"
              name="gradoGrupo"
              value={itemBusqueda.gradoGrupo}
              onChange={({ target }) => setItemBusqueda(items => ({ ...items, gradoGrupo: target.value }))}
              disabled={!!itemBusqueda.busqueda}
            >
              <option value="">Selecciona un grado y grupo</option>
              {catalogos.gradosGrupos.map((grado: IGradoGrupoCatalogo) => (
                <option key={grado.id} value={grado.gradoGrupo}>
                  {grado.gradoGrupo}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Table striped hover>
            <thead>
              <tr>
                <th>Foto</th>
                <th>Nombre Completo</th>
                <th>Nivel Escolar</th>
                <th>Grado/Grupo</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {estudiantes.length > 0 ? (
                <>
                  {estudiantes.map((est: IAlumnoDetalle) => (
                    <tr key={est.id}>
                      <td>
                        <img
                          className="estudiantes__foto"
                          src={est.foto ? `data:image/png;base64,${est.foto}` : placeholderImage}
                          alt="avatar"
                        />
                      </td>
                      <td>{est.nombreCompleto ?? `${est.nombres} ${est.apellidoPaterno} ${est.apellidoMaterno}`}</td>
                      <td>{est.nivelEscolar}</td>
                      <td>{est.gradoGrupo}</td>
                      <td>
                        <DescriptionIcon
                          className="estudiantes__accion"
                          onClick={() => navigate(`${ROUTES.ESTUDIANTES}/${est.id}`)}
                        />
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={5}>No hay estudiantes registrados.</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}
