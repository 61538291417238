import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, InputGroup, Table } from 'react-bootstrap';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';

import Title from '../components/Title';
import useError from '../hooks/useError';
import { ROUTES } from '../constants/routes';
import Loading from '../components/Loading';
import EliminarUsuarioModal from '../components/Modals/EliminarUsuarioModal';
import { IUsuario } from '../models/interfaces/usuario/IUsuario';
import { ICargando } from '../models/interfaces/ICargando';
import { getUsuariosService, deleteUsuariosService } from '../services/usuarios.service';

import '../styles/Roles.scss';

export default function Roles() {
  const [usuarios, setUsuarios] = useState<IUsuario[]>([]);
  const [usuariosAux, setUsuariosAux] = useState<IUsuario[]>([]);
  const [usuarioSeleccionado, setUsuarioSeleccionado] = useState<IUsuario>({
    id: '',
    fullName: '',
    userName: '',
    phoneNumber: '',
    roles: [],
  });
  const [itemBusqueda, setItemBusqueda] = useState<string>('');
  const [modalEliminar, setModalEliminar] = useState<boolean>(false);
  const [cargando, setCargando] = useState<ICargando>({
    cargando: false,
    mensaje: '',
  });
  const { ErrorAlert, setError } = useError();
  const navigate = useNavigate();

  const buscarUsuario = (userParam: string) => {
    setItemBusqueda(userParam);
    const usuariosFiltrados = usuariosAux.filter(
      (usr: IUsuario) =>
        usr.fullName.toLowerCase().includes(userParam.toLowerCase()) ||
        usr.roles.join(',').toLowerCase().includes(userParam.toLowerCase())
    );
    setUsuarios(usuariosFiltrados);
  };

  const obtenerUsuarios = async () => {
    try {
      setCargando({
        cargando: true,
        mensaje: 'Obteniendo usuarios',
      });
      const response = await getUsuariosService();
      setUsuarios(response);
      setUsuariosAux(response);
    } catch (ex) {
      setError(ex);
    } finally {
      setCargando({
        cargando: false,
        mensaje: '',
      });
    }
  };

  const eliminarUsuario = (usr: IUsuario) => {
    setUsuarioSeleccionado(usr);
    setModalEliminar(true);
  };

  const eliminacionUsuarioConfirmada = async (idUsuario: string) => {
    try {
      await deleteUsuariosService(idUsuario);
      setUsuarios((usrs: IUsuario[]) => usrs.filter(usr => usr.id !== idUsuario));
      setUsuariosAux((usrs: IUsuario[]) => usrs.filter(usr => usr.id !== idUsuario));
      setModalEliminar(false);
    } catch (ex) {
      setError(ex);
    }
  };

  useEffect(() => {
    obtenerUsuarios();
    return () => {
      setCargando({
        cargando: false,
        mensaje: '',
      });
      setItemBusqueda('');
      setUsuarios([]);
    };
  }, []);

  return (
    <Container className="roles d-flex flex-column">
      <ErrorAlert />
      <Title titulo="Roles de Usuario" />
      {cargando.cargando && <Loading mensaje={cargando.mensaje} />}
      <Row className="mt-5">
        <Col className="my-3 d-flex align-items-end justify-content-between" xs={7} sm={6} md={6} lg={6}>
          <InputGroup>
            <Form.Control
              name="buscar"
              value={itemBusqueda}
              onChange={({ target }) => buscarUsuario(target.value)}
              placeholder="Nombre Completo / Rol"
            />
            <InputGroup.Text id="search">
              <SearchIcon />
            </InputGroup.Text>
          </InputGroup>
        </Col>
        <Col className="my-3 d-flex align-items-end justify-content-end" xs={5} sm={6} md={6} lg={6}>
          <Button className="text-white" variant="secondary" onClick={() => navigate(ROUTES.REGISTRAR_USUARIO)}>
            Nuevo Usuario
          </Button>
        </Col>
      </Row>
      <Row className="my-5">
        <Col xs={12} sm={12} md={12} lg={12}>
          <Table responsive striped hover>
            <thead>
              <tr>
                <th>Roles</th>
                <th>Usuario</th>
                <th>Nombre</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {usuarios.length > 0 ? (
                <>
                  {usuarios.map((usr: IUsuario) => (
                    <tr key={usr.id}>
                      <td>{usr.roles.join(', ')}</td>
                      <td>{usr.userName}</td>
                      <td>{usr.fullName}</td>
                      <td>
                        <EditIcon
                          className="roles__accion"
                          onClick={() => navigate(`${ROUTES.EDITAR_USUARIO}/${usr.id}`)}
                        />
                        <DeleteIcon className="roles__accion" onClick={() => eliminarUsuario(usr)} />
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <>
                  <tr>
                    <td colSpan={4}>No hay usuarios registrados</td>
                  </tr>
                </>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      <EliminarUsuarioModal
        estado={modalEliminar}
        cambiarEstado={setModalEliminar}
        recuperarUsuario={eliminacionUsuarioConfirmada}
        contenido={usuarioSeleccionado}
      />
    </Container>
  );
}
