export enum SEXO {
  HOMBRE = 1,
  MUJER,
  OTRO,
}

export enum SEXO_TEXTO {
  HOMBRE_TEXTO = 'HOMBRE',
  MUJER_TEXTO = 'MUJER',
  OTRO_TEXTO = 'OTRO',
}