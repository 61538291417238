import { Card } from 'react-bootstrap';

import { ItemProps } from '@app/models/types/acceso/item.type';

import '../../styles/components/ItemAcceso.component.scss';

export default function Item(props: ItemProps) {
  const { imagen, titulo } = props;

  return (
    <Card className="item-acceso">
      <Card.Body className="item-acceso__contenedor">
        <img className="item-acceso__imagen" src={imagen} alt="Estudiantes" />
        <h2 className="item-acceso__titulo">{titulo}</h2>
      </Card.Body>
    </Card>
  );
}
