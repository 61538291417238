import { useState, useEffect } from 'react';
import { Container, Row, Col, Button, InputGroup, Form, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';

import { ROUTES } from '../constants/routes';
import Title from '../components/Title';
import Loading from '../components/Loading';
import useError from '../hooks/useError';
import { ITutor } from '../models/interfaces/tutores/ITutor';
import { ICargando } from '../models/interfaces/ICargando';
import { getPadreTutorService } from '../services/tutores.service';

import '../styles/Tutores.scss';

export default function Tutores() {
  const [itemBusqueda, setItemBusqueda] = useState<string>('');
  const [tutores, setTutores] = useState<ITutor[]>([]);
  const [tutoresAux, setTutoresAux] = useState<ITutor[]>([]);
  const [cargando, setCargando] = useState<ICargando>({
    cargando: false,
    mensaje: '',
  });
  const { setError, ErrorAlert } = useError();
  const navigate = useNavigate();

  const buscarTutor = (tutorParam: string) => {
    setItemBusqueda(tutorParam);
    const tutoresFiltrados = tutoresAux.filter((tut: ITutor) =>
      tut.nombreCompleto.toLowerCase().includes(tutorParam.toLowerCase())
    );
    setTutores(tutoresFiltrados);
  };

  const obtenerTutores = async () => {
    try {
      setCargando({
        cargando: true,
        mensaje: 'Obteniendo tutores',
      });
      setTutores(await getPadreTutorService());
      setTutoresAux(await getPadreTutorService());
    } catch (ex) {
      setError(ex);
    } finally {
      setCargando({
        cargando: false,
        mensaje: '',
      });
    }
  };

  useEffect(() => {
    obtenerTutores();
    return () => {
      setTutores([]);
      setTutoresAux([]);
      setCargando({
        cargando: false,
        mensaje: '',
      });
    };
  }, []);

  return (
    <Container className="tutores d-flex flex-column">
      <ErrorAlert />
      {cargando.cargando && <Loading mensaje={cargando.mensaje} />}
      <Title titulo="Padres o Tutores" ruta={ROUTES.ACCESO} />
      <Row className="my-4">
        <Col className="my-3 d-flex align-items-end justify-content-between" xs={7} sm={6} md={6} lg={6}>
          <InputGroup>
            <Form.Control
              name="buscar"
              value={itemBusqueda}
              onChange={({ target }) => buscarTutor(target.value)}
              placeholder="Nombre Padre / Tutor"
            />
            <InputGroup.Text id="search">
              <SearchIcon />
            </InputGroup.Text>
          </InputGroup>
        </Col>
        <Col className="my-3 d-flex align-items-end justify-content-end" xs={5} sm={6} md={6} lg={6}>
          <Button className="text-white" variant="secondary" onClick={() => navigate(ROUTES.REGISTRAR_TUTOR)}>
            Nuevo Padre o Tutor
          </Button>
        </Col>
      </Row>
      <Row>
        <Col className="my-3" xs={12} sm={12} md={12} lg={12}>
          <Table>
            <thead>
              <tr>
                <th>Nombre Padre / Tutor</th>
                <th>Alumnos</th>
              </tr>
            </thead>
            <tbody>
              {tutores.length > 0 ? (
                <>
                  {tutores.map((tutor: ITutor) => (
                    <tr
                      className="tutores__fila-tutor"
                      key={tutor.id}
                      onClick={() => navigate(`${ROUTES.TUTORES_PUPILOS}/${tutor.id}`)}
                    >
                      <td>{tutor.nombreCompleto}</td>
                      <td>
                        <div className="tutores__cantidad-alumnos">{tutor.alumnos.length}</div>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={2}>No hay padres o tutores registrados</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}
