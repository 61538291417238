import { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Table } from 'react-bootstrap';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import Title from '../components/Title';
import { ROUTES } from '../constants/routes';
import { IAcceso } from '../models/interfaces/IAcceso';
import { accesosMock } from '../utils/mocks/accesos.mock';
import AgregarAreaAccesoModal from '../components/Modals/AgregarAreaAccesoModal';

import '../styles/AreaAcceso.scss';

export default function AreaAcceso() {
  const [estadoModal, setEstadoModal] = useState<boolean>(false);
  const [accesos, setAccesos] = useState<IAcceso[]>([]);

  const obtenerAccesos = () => {
    setAccesos(accesosMock);
  };

  const recuperarAreaAcceso = (areaAcceso: string) => {
    setAccesos((acceso: IAcceso[]) => [...acceso, { id: accesos.length + 1, acceso: areaAcceso }]);
    setEstadoModal(false);
  };

  useEffect(() => {
    obtenerAccesos();
  }, []);

  return (
    <Container className="area-acceso d-flex flex-column">
      <Title titulo="Áreas de Acceso" ruta={ROUTES.ACCESO} />
      <Row className="my-4">
        <Col className="d-flex align-items-center justify-content-end" xs={12} sm={12} md={12} lg={12}>
          <Button variant="secondary" className="text-white" onClick={() => setEstadoModal(true)}>
            Agregar Acceso
          </Button>
        </Col>
      </Row>
      <Row className="my-4">
        <Col xs={12} sm={12} md={12} lg={12}>
          <Table striped hover>
            <thead>
              <tr>
                <th>Acceso</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {accesos.length > 0 ? (
                <>
                  {accesos.map((acceso: IAcceso) => (
                    <tr key={acceso.id}>
                      <td>{acceso.acceso}</td>
                      <td>
                        <EditIcon className="area-acceso__acciones" />
                        <DeleteIcon className="area-acceso__acciones" />
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={2}>No hay accesos registrados</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      <AgregarAreaAccesoModal estado={estadoModal} cambiarEstado={setEstadoModal} recuperarDato={recuperarAreaAcceso} />
    </Container>
  );
}
