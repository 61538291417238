import { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';

import Title from '../components/Title';
import Loading from '../components/Loading';
import { ROUTES } from '../constants/routes';
import { getAlumnoService } from '../services/alumnos.service';
import { ICargando } from '../models/interfaces/ICargando';
import { IAlumnoDetalle } from '../models/interfaces/alumnos/IAlumno';
import { SEXO, SEXO_TEXTO } from '../models/enums/sexo.enum';
import useError from '../hooks/useError';
import placeholderImage from '../assets/images/placeholder.png';

import '../styles/DetalleEstudiante.scss';

export default function DetalleEstudiante() {
  const [estudiante, setEstudiante] = useState<IAlumnoDetalle>({
    id: 0,
    padreTutorId: 0,
    nombres: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    nombreCompleto: '',
    fechaNacimiento: '',
    sexo: 0,
    correo: '',
    domicilio: '',
    alergias: false,
    descripcionAlergias: '',
    descripcionDiscapacidad: '',
    descripcionEnfermedadesCronicas: '',
    descripcionMedicamentosControlados: '',
    descripcionTipoAcceso: '',
    discapacidad: false,
    enfermedadesCronicas: false,
    fecha: '',
    foto: '',
    gradoGrupo: '',
    hora: '',
    medicamentosControlados: false,
    nivelEscolar: '',
    tarjetaId: 0,
    telefono: '',
    tipoAcceso: 0,
    tipoDeSangre: '',
    padreTutor: {
      id: 0,
      nombreCompleto: '',
    },
    contactos: [],
  });
  const [cargando, setCargando] = useState<ICargando>({
    cargando: false,
    mensaje: '',
  });
  const { id } = useParams();
  const { setError, ErrorAlert } = useError();

  const obtenerDetalleEstudiante = async () => {
    try {
      setCargando({
        cargando: true,
        mensaje: 'Cargando alumno...',
      });
      const response = await getAlumnoService(+id);
      setEstudiante(response);
    } catch (ex) {
      setError(ex);
    } finally {
      setCargando({
        cargando: false,
        mensaje: '',
      });
    }
  };

  useEffect(() => {
    obtenerDetalleEstudiante();
  }, []);

  return (
    <Container className="detalle-estudiante d-flex flex-column">
      <ErrorAlert />
      {cargando.cargando && <Loading mensaje={cargando.mensaje} />}
      <Title titulo="Detalle Alumno" ruta={ROUTES.ESTUDIANTES} />
      <Row className="my-5">
        <Col xs={6} sm={3} md={3} lg={3}>
          <img
            className="detalle-estudiante__avatar"
            src={!!estudiante.foto ? `data:image/png;base64,${estudiante.foto}` : placeholderImage}
            alt={estudiante.nombres}
          />
        </Col>
        <Col xs={6} sm={9} md={9} lg={9}>
          <Card>
            <Card.Header>
              <Card.Title>PROGRAMA EDUCATIVO</Card.Title>
            </Card.Header>
            <Card.Body className="d-flex algin-items-center justify-content-between p-4 w-75">
              <h6 className="detalle-estudiante__item">
                Grado/Grupo: <span className="fw-normal">{estudiante.gradoGrupo}</span>
              </h6>
              <h6 className="detalle-estudiante__item">
                Nivel: <span className="fw-normal">{estudiante.nivelEscolar}</span>
              </h6>
              <h6 className="detalle-estudiante__item">
                Número Tarjeta: <span className="fw-normal">{estudiante.tarjetaId}</span>
              </h6>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="my-4">
        <Col xs={12} sm={12} md={12} lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>DATOS GENERALES</Card.Title>
            </Card.Header>
            <Card.Body className="d-flex algin-items-center justify-content-between flex-wrap p-4">
              <div className="d-flex algin-items-center justify-content-between my-2 w-75">
                <h6 className="detalle-estudiante__item">
                  Nombre Completo:&nbsp;
                  <span className="fw-normal">
                    {estudiante.nombres} {estudiante.apellidoPaterno} {estudiante.apellidoMaterno}
                  </span>
                </h6>
                <h6 className="detalle-estudiante__item">
                  Fecha Nacimiento:&nbsp;
                  <span className="fw-normal">
                    {!!estudiante.fechaNacimiento ? format(new Date(estudiante.fechaNacimiento), 'dd/MM/yyyy') : ''}
                  </span>
                </h6>
                <h6 className="detalle-estudiante__item">
                  Sexo:&nbsp;
                  <span className="fw-normal">{SEXO_TEXTO[`${SEXO[estudiante.sexo]}_TEXTO`]}</span>
                </h6>
              </div>
              <div className="d-flex align-items-center justify-content-between my-2 w-100">
                <h6 className="detalle-estudiante__item">
                  Código Postal:&nbsp;
                  <span className="fw-normal">{estudiante.domicilio.split('$')[0]}</span>
                </h6>
                <h6 className="detalle-estudiante__item">
                  Colonia:&nbsp;
                  <span className="fw-normal">{estudiante.domicilio.split('$')[1]}</span>
                </h6>
                <h6 className="detalle-estudiante__item">
                  Calle:&nbsp;
                  <span className="fw-normal">{estudiante.domicilio.split('$')[2]}</span>
                </h6>
                <h6 className="detalle-estudiante__item">
                  No. Exterior:&nbsp;
                  <span className="fw-normal">{estudiante.domicilio.split('$')[3]}</span>
                </h6>
              </div>
              <div className="d-flex align-items-center justify-content-between my-2 w-50">
                <h6 className="detalle-estudiante__item">
                  Teléfono:&nbsp;
                  <span className="fw-normal">{estudiante.telefono}</span>
                </h6>
                <h6 className="detalle-estudiante__item">
                  Correo:&nbsp;
                  <span className="fw-normal">{estudiante.correo}</span>
                </h6>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>DATOS MÉDICOS</Card.Title>
            </Card.Header>
            <Card.Body className="d-flex algin-items-center justify-content-between flex-wrap p-4">
              <div className="d-flex algin-items-center justify-content-between my-2 w-75">
                <h6 className="detalle-estudiante__item">
                  Tipo de Sangre:&nbsp;
                  <span className="fw-normal">{estudiante.tipoDeSangre}</span>
                </h6>
                <h6 className="detalle-estudiante__item">
                  Discapacidad:&nbsp;
                  <span className="fw-normal">{estudiante.discapacidad ? estudiante.descripcionDiscapacidad : ''}</span>
                </h6>
                <h6 className="detalle-estudiante__item">
                  Alergías:&nbsp;
                  <span className="fw-normal">{estudiante.alergias ? estudiante.descripcionAlergias : ''}</span>
                </h6>
              </div>
              <div className="d-flex algin-items-center justify-content-between my-2 w-75">
                <h6 className="detalle-estudiante__item">
                  Medicamentos Controlados:&nbsp;
                  <span className="fw-normal">
                    {estudiante.medicamentosControlados ? estudiante.descripcionMedicamentosControlados : ''}
                  </span>
                </h6>
                <h6 className="detalle-estudiante__item">
                  Enfermedades Crónicas:&nbsp;
                  <span className="fw-normal">
                    {estudiante.enfermedadesCronicas ? estudiante.descripcionEnfermedadesCronicas : ''}
                  </span>
                </h6>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
