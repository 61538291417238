import { useState, useEffect, useRef } from 'react';
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';

import { AreaAccesoProps } from '../../models/types/modals/area-acceso.type';

export default function AgregarTarjeta(props: AreaAccesoProps) {
  const { estado, cambiarEstado, recuperarDato } = props;
  const [areaAcceso, setAreaAcceso] = useState<string>('');
  const refCampo = useRef(null);

  useEffect(() => {
    if (estado) {
      refCampo.current.focus();
    } else {
      setAreaAcceso('');
    }
  }, [estado]);

  return (
    <Modal show={estado} onHide={() => cambiarEstado(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Agregar Área Acceso</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="my-3">
          <Col xs={12} sm={12} md={12} lg={12}>
            <Form.Group>
              <Form.Label>Área Acceso</Form.Label>
              <Form.Control
                ref={refCampo}
                name="areaAcceso"
                value={areaAcceso}
                onChange={({ target }) => setAreaAcceso(target.value)}
                placeholder="Área Acceso"
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={() => cambiarEstado(false)}>
          Cancelar
        </Button>
        <Button className="text-white" variant="secondary" onClick={() => recuperarDato(areaAcceso)}>
          Aceptar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
