import { Container } from 'react-bootstrap';

import Title from '../components/Title';

export default function Temas() {
  return (
    <Container className="d-flex flex-column">
      <Title titulo="Temas de Plataforma" />
    </Container>
  );
}
