import { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Card, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';

import Title from '../components/Title';
import Loading from '../components/Loading';
import { IRegistrarTutor } from '../models/interfaces/tutores/IRegistrarTutor';
import { ICargando } from '../models/interfaces/ICargando';
import { IColonia } from '../models/interfaces/IColonia';
import { registroTutorValidationSchema } from '../utils/validations/registro-tutor.validation';
import { buscarColoniasService } from '../services/localidades.service';
import { addPadreTutorService } from '../services/tutores.service';
import { getParentescosService } from '../services/tutores.service';
import { IParentesco } from '@app/models/interfaces/tutores/IParentesco';
import { ROUTES } from '../constants/routes';
import useError from '../hooks/useError';

import '../styles/RegistroTutor.scss';

export default function RegistroTutor() {
  const [cargando, setCargando] = useState<ICargando>({
    cargando: false,
    mensaje: '',
  });
  const [mensajeColonias, setMensajeColonias] = useState<string>('');
  const [colonias, setColonias] = useState<IColonia[]>([]);
  const [parentescos, setParentescos] = useState<IParentesco[]>([]);
  const initialValues: IRegistrarTutor = {
    nombreCompleto: '',
    parentescoId: 0,
    telefono: '',
    autorizoSerContactado: false,
    codigoPostal: '',
    estado: '',
    municipio: '',
    colonia: '',
    calle: '',
    numeroExterior: '',
    esContactoPrioritario: false,
  };
  const { setError, ErrorAlert } = useError();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues,
    onSubmit: (values: IRegistrarTutor) => guardarTutor(values),
    validationSchema: registroTutorValidationSchema,
  });

  const obtenerLocalidad = async (codigoPostal: string) => {
    try {
      if (codigoPostal.length > 4 || !!!Object.keys(formik.errors).includes('codigoPostal')) {
        const response = await buscarColoniasService(codigoPostal);
        setColonias(response);
        if (!!response.length) {
          formik.setFieldValue('estado', response[0].estadoNombre);
          formik.setFieldValue('municipio', response[0].municipioNombre);
          formik.setFieldValue('colonia', '');
        } else {
          setMensajeColonias('No se encontraron colonias para el código postal');
        }
      } else if (codigoPostal.length < 5) {
        setColonias([]);
        formik.setFieldValue('estado', '');
        formik.setFieldValue('municipio', '');
        formik.setFieldValue('colonia', '');
        setMensajeColonias('');
      }
    } catch (ex) {
      setError(ex);
    }
  };

  const obtenerParentescos = async () => {
    try {
      setParentescos(await getParentescosService());
    } catch (ex) {
      setError(ex);
    }
  };

  const guardarTutor = async (requestTutorData: IRegistrarTutor) => {
    try {
      setCargando({
        cargando: true,
        mensaje: 'Guardando Padre o Tutor',
      });
      await addPadreTutorService({ ...requestTutorData, parentescoId: +requestTutorData.parentescoId });
      navigate(ROUTES.TUTORES);
    } catch (ex) {
      setError(ex);
    } finally {
      setCargando({
        cargando: false,
        mensaje: '',
      });
    }
  };

  useEffect(() => {
    obtenerParentescos();
    return () => {
      setColonias([]);
      setMensajeColonias('');
      setCargando({
        cargando: false,
        mensaje: '',
      });
      formik.resetForm();
    };
  }, []);

  return (
    <Container className="registro-tutor d-flex flex-column">
      {cargando.cargando && <Loading mensaje={cargando.mensaje} />}
      <ErrorAlert />
      <Title titulo="Registro Padre o Tutor" ruta={ROUTES.TUTORES} />
      <form onSubmit={formik.handleSubmit}>
        <Row className="mt-4">
          <Col xs={12} sm={12} md={6} lg={6}>
            <p>
              Los campos con <span className="registro-tutor__required">*</span> son obligatorios.
            </p>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={12} sm={12} md={5} lg={6}>
            <Form.Group>
              <Form.Label>
                Nombre Completo Padre o Tutor&nbsp;<span className="registro-tutor__required">*</span>
              </Form.Label>
              <Form.Control
                isInvalid={!!formik.errors.nombreCompleto}
                name="nombreCompleto"
                value={formik.values.nombreCompleto}
                onChange={formik.handleChange}
                placeholder="Nombre Completo Padre o Tutor"
              />
              <Form.Control.Feedback className="d-block" type="invalid">
                {formik.errors.nombreCompleto}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} sm={12} md={4} lg={3}>
            <Form.Group>
              <Form.Label>
                Parentesco&nbsp;<span className="registro-tutor__required">*</span>
              </Form.Label>
              <Form.Control
                as="select"
                isInvalid={!!formik.errors.parentescoId}
                name="parentescoId"
                value={formik.values.parentescoId}
                onChange={formik.handleChange}
                placeholder="Parantesco"
              >
                <option value={0}>Seleccionar Parentesco</option>
                {parentescos.map((parent: IParentesco) => (
                  <option key={parent.id} value={parent.id}>
                    {parent.parentesco}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback className="d-block" type="invalid">
                {formik.errors.parentescoId}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={6} sm={6} md={3} lg={3}>
            <Form.Group>
              <Form.Label>
                Teléfono&nbsp;<span className="registro-tutor__required">*</span>
              </Form.Label>
              <Form.Control
                isInvalid={!!formik.errors.telefono}
                name="telefono"
                value={formik.values.telefono}
                onChange={formik.handleChange}
                placeholder="Teléfono"
              />
              <Form.Control.Feedback className="d-block" type="invalid">
                {formik.errors.telefono}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="my-4">
          <Col xs={12} sm={12} md={8} lg={4}>
            <Form.Group>
              <Form.Label>Autorizo ser contactado/a para recibir notificaciones de mi hijo/pupilo</Form.Label>
              <Form.Check
                inline
                type="radio"
                id="radio-autorizo-no"
                label="No"
                isInvalid={!!formik.errors.autorizoSerContactado}
                name="autorizoSerContactado"
                value=""
                onChange={({ target }) => formik.setFieldValue('autorizoSerContactado', !!target.value)}
                defaultChecked
              />
              <Form.Check
                inline
                type="radio"
                id="radio-autorizo-si"
                label="Si"
                isInvalid={!!formik.errors.autorizoSerContactado}
                name="autorizoSerContactado"
                value="Si"
                onChange={({ target }) => formik.setFieldValue('autorizoSerContactado', !!target.value)}
              />
              <Form.Control.Feedback className="d-block" type="invalid">
                {formik.errors.autorizoSerContactado}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="my-4">
          <Col xs={12} sm={12} md={12} lg={12}>
            <Card>
              <Card.Header>
                Domicilio Actual{' '}
                {!!mensajeColonias && !!!Object.keys(formik.errors).includes('codigoPostal') && (
                  <>
                    -&nbsp;<span className="text-danger fw-500">{mensajeColonias}</span>
                  </>
                )}
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col xs={4} sm={4} md={3} lg={3}>
                    <Form.Group>
                      <Form.Label>
                        Código Postal&nbsp;<span className="registro-tutor__required">*</span>
                      </Form.Label>
                      <Form.Control
                        isInvalid={!!formik.errors.codigoPostal}
                        name="codigoPostal"
                        value={formik.values.codigoPostal}
                        onChange={event => {
                          formik.handleChange(event);
                          obtenerLocalidad(event.target.value);
                        }}
                        placeholder="Código Postal"
                      />
                      <Form.Control.Feedback className="d-block" type="invalid">
                        {formik.errors.codigoPostal}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4}>
                    <Form.Group>
                      <Form.Label>Estado</Form.Label>
                      <Form.Control
                        isInvalid={!!formik.errors.estado}
                        name="estado"
                        value={formik.values.estado}
                        onChange={formik.handleChange}
                        placeholder="Estado"
                        disabled
                      />
                      <Form.Control.Feedback className="d-block" type="invalid">
                        {formik.errors.estado}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={4} sm={4} md={5} lg={5}>
                    <Form.Group>
                      <Form.Label>Municipio</Form.Label>
                      <Form.Control
                        isInvalid={!!formik.errors.municipio}
                        name="municipio"
                        value={formik.values.municipio}
                        onChange={formik.handleChange}
                        placeholder="Municipio"
                        disabled
                      />
                      <Form.Control.Feedback className="d-block" type="invalid">
                        {formik.errors.municipio}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="my-4">
                  <Col xs={6} sm={6} md={6} lg={6}>
                    <Form.Group>
                      <Form.Label>
                        Colonia&nbsp;<span className="registro-tutor__required">*</span>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        isInvalid={!!formik.errors.colonia}
                        name="colonia"
                        value={formik.values.colonia}
                        onChange={formik.handleChange}
                        placeholder="Colonia"
                      >
                        <option value="">Seleccionar Colonia</option>
                        {colonias.map((col: IColonia) => (
                          <option key={col.id} value={col.nombreColonia}>
                            {col.nombreColonia.toUpperCase()}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback className="d-block" type="invalid">
                        {formik.errors.colonia}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={6}>
                    <Form.Group>
                      <Form.Label>
                        Calle&nbsp;<span className="registro-tutor__required">*</span>
                      </Form.Label>
                      <Form.Control
                        isInvalid={!!formik.errors.calle}
                        name="calle"
                        value={formik.values.calle}
                        onChange={formik.handleChange}
                        placeholder="Calle"
                      />
                      <Form.Control.Feedback className="d-block" type="invalid">
                        {formik.errors.calle}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="my-4">
                  <Col xs={3} sm={3} md={3} lg={3}>
                    <Form.Group>
                      <Form.Label>
                        Número Exterior&nbsp;<span className="registro-tutor__required">*</span>
                      </Form.Label>
                      <Form.Control
                        isInvalid={!!formik.errors.numeroExterior}
                        name="numeroExterior"
                        value={formik.values.numeroExterior}
                        onChange={formik.handleChange}
                        placeholder="Número Exterior"
                      />
                      <Form.Control.Feedback className="d-block" type="invalid">
                        {formik.errors.numeroExterior}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={3} sm={3} md={3} lg={2}>
                    <Form.Group>
                      <Form.Label>Contacto Prioritario</Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        id="radio-contacto-no"
                        label="No"
                        isInvalid={!!formik.errors.esContactoPrioritario}
                        name="esContactoPrioritario"
                        value=""
                        onChange={({ target }) => formik.setFieldValue('esContactoPrioritario', !!target.value)}
                        defaultChecked
                      />
                      <Form.Check
                        inline
                        type="radio"
                        id="radio-contacto-si"
                        label="Si"
                        isInvalid={!!formik.errors.esContactoPrioritario}
                        name="esContactoPrioritario"
                        value="true"
                        onChange={({ target }) => formik.setFieldValue('esContactoPrioritario', !!target.value)}
                      />
                      <Form.Control.Feedback className="d-block" type="invalid">
                        {formik.errors.esContactoPrioritario}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col className="d-flex align-items-end justify-content-end" xs={6} sm={6} md={6} lg={7}>
                    <Button className="text-white" type="submit" variant="secondary">
                      Guardar Padre o Tutor
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </form>
    </Container>
  );
}
