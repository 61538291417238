import http from '../utils/http';
import { IAccesoRegistro } from '../models/interfaces/accesos/IAccesoRegistro';

export const getAccesoPlantelService = async (requestAcceso: IAccesoRegistro) => {
  try {
    const response = await http.post('Acceso', requestAcceso);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const getUltimosAccesosService = async () => {
  try {
    const response = await http.get('Acceso/GetUltimosAccesos');
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const getLectoras = async () => {
  try {
    const response = await http.get('Lectora');
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};
