import { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Table } from 'react-bootstrap';
import DeleteIcon from '@mui/icons-material/Delete';

import Title from '../components/Title';
import AgregarCatalogoModal from '../components/Modals/AgregarCatalogoModal';
import AlertaEliminarModal from '../components/Modals/AlertaEliminarModal';
import { CATALOGOS, CATALOGOS_TEXTO } from '../models/enums/catalogos.enum';
import { addCatalogosService, deleteCatalogosService, getCatalogosService } from '../services/catalogos.service';
import { ICatalogo } from '../models/interfaces/catalogos/ICatalogo';
import { IAgregarNivelCatalogo, IAgregarGradoGrupoCatalogo } from '../models/interfaces/catalogos/IAgregarCatalogo';
import useError from '../hooks/useError';

import '../styles/Catalogos.scss';

export default function Catalogos() {
  const [tipoCatalogo, setTipoCatalogo] = useState<number>(1);
  const [estadoModal, setEstadoModal] = useState<boolean>(false);
  const [estadoEliminar, setEstadoEliminar] = useState<{
    estado: boolean;
    data: {
      id: number;
      elemento: string;
    };
  }>({
    estado: false,
    data: {
      id: 0,
      elemento: '',
    },
  });
  const [guardandoCatalogo, setGuardandoCatalogo] = useState<boolean>(false);
  const [catalogos, setCatalogos] = useState<ICatalogo>({
    nivelesEscolares: [],
    gradosGrupos: [],
  });

  const { setError, ErrorAlert } = useError();

  const obtenerCatalogos = async () => {
    try {
      const response = await getCatalogosService();
      setCatalogos(response);
    } catch (ex) {
      setError(ex);
    }
  };

  const agregarCatalogo = async (
    nombreCatalogo: string,
    dataCatalogo: IAgregarNivelCatalogo | IAgregarGradoGrupoCatalogo
  ) => {
    try {
      const propiedadCatalogoUtilizado = tipoCatalogo === 1 ? 'nivelesEscolares' : 'gradosGrupos';
      const response = await addCatalogosService(nombreCatalogo, dataCatalogo);
      setCatalogos((catalogos: ICatalogo) => ({
        ...catalogos,
        [propiedadCatalogoUtilizado]: [...catalogos[propiedadCatalogoUtilizado], response],
      }));
      setEstadoModal(false);
    } catch (ex) {
      setError(ex);
    } finally {
      setGuardandoCatalogo(false);
    }
  };

  const eliminarCatalogo = async (idCatalogoEliminado: number) => {
    try {
      const propiedadCatalogoUtilizado = tipoCatalogo === 1 ? 'nivelesEscolares' : 'gradosGrupos';
      await deleteCatalogosService(idCatalogoEliminado, `${CATALOGOS[tipoCatalogo]}_TEXTO`);
      setCatalogos((cat: ICatalogo) => ({
        ...cat,
        [propiedadCatalogoUtilizado]: [...cat[propiedadCatalogoUtilizado]].filter(
          cmp => cmp.id !== idCatalogoEliminado
        ),
      }));
    } catch (ex) {
      setError(ex);
    } finally {
      setEstadoEliminar(estEliminar => ({ ...estEliminar, estado: false }));
    }
  };

  //i Se verifica el tipo de catálogo que se va a agregar y en base a eso se hace el POST (llamando un método)
  const recuperarCatalogo = (descripcionCatalogo: string) => {
    if (CATALOGOS_TEXTO[`${CATALOGOS[tipoCatalogo]}_TEXTO`].toLowerCase().includes('nivel')) {
      agregarCatalogo('nivel', { nivelEscolar: descripcionCatalogo });
    } else if (CATALOGOS_TEXTO[`${CATALOGOS[tipoCatalogo]}_TEXTO`].toLowerCase().includes('grado')) {
      agregarCatalogo('grado', { gradoGrupo: descripcionCatalogo });
    }
  };

  const recuperarCatalogoEliminado = (idCatalogEliminado: number) => eliminarCatalogo(idCatalogEliminado);

  useEffect(() => {
    obtenerCatalogos();
  }, []);

  return (
    <Container className="catalogo d-flex flex-column">
      <ErrorAlert />
      <Title titulo={`Catálogo ${CATALOGOS_TEXTO[`${CATALOGOS[tipoCatalogo]}_TEXTO`]}`} />
      <Row className="my-4">
        <Col xs={12} sm={4} md={4} lg={4}>
          <Form.Group>
            <Form.Label>Catálogo</Form.Label>
            <Form.Control
              as="select"
              name="catalogo"
              value={tipoCatalogo}
              onChange={({ target }) => setTipoCatalogo(+target.value)}
            >
              <option value={CATALOGOS.NIVEL}>{CATALOGOS_TEXTO.NIVEL_TEXTO}</option>
              <option value={CATALOGOS.GRADO_GRUPO}>{CATALOGOS_TEXTO.GRADO_GRUPO_TEXTO}</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col className="d-flex align-items-end justify-content-end" xs={12} sm={8} md={8} lg={8}>
          <Button variant="secondary" className="text-white" onClick={() => setEstadoModal(true)}>
            Agregar {CATALOGOS_TEXTO[`${CATALOGOS[tipoCatalogo]}_TEXTO`]}
          </Button>
        </Col>
      </Row>
      <Row className="my-4">
        <Col xs={12} sm={12} md={12} lg={12}>
          <Table striped hover>
            <thead>
              <tr>
                <th>Catálogo</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {Object.values(catalogos)[tipoCatalogo - 1].length > 0 ? (
                <>
                  {Object.values(catalogos)[tipoCatalogo - 1]?.map(cat => (
                    <tr key={cat.id}>
                      <td>{cat?.nivelEscolar ?? cat?.gradoGrupo}</td>
                      <td>
                        <DeleteIcon
                          className="catalogo__accion"
                          onClick={() =>
                            setEstadoEliminar({
                              estado: true,
                              data: {
                                id: cat.id,
                                elemento: cat?.nivelEscolar ?? cat?.gradoGrupo,
                              },
                            })
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={2}>
                    No hay {CATALOGOS_TEXTO[`${CATALOGOS[tipoCatalogo]}_TEXTO`].toLowerCase()} registrados
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      <AgregarCatalogoModal
        estado={estadoModal}
        cambiarEstado={setEstadoModal}
        recuperarDato={recuperarCatalogo}
        dataCatalogo={{ catalogo: CATALOGOS_TEXTO[`${CATALOGOS[tipoCatalogo]}_TEXTO`] }}
        guardando={{ estadoGuardando: guardandoCatalogo, cambiarEstadoGuardando: setGuardandoCatalogo }}
      />
      <AlertaEliminarModal
        estado={estadoEliminar.estado}
        cambiarEstado={setEstadoEliminar}
        recuperarElemento={recuperarCatalogoEliminado}
        dataEliminar={estadoEliminar.data}
      />
    </Container>
  );
}
